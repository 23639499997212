import styled from 'styled-components';

export const PageContent = styled.section`
  display: flex;
  width: 100%;
  height: calc(100vh - 190px);
  flex-direction: column;
  padding: 32px 0;
  align-items: center;
`;

export const InfoBanner = styled.article`
  width: 100%;
  padding: 16px;
  background: #fff;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
`;

export const InfoBannerTxt = styled.p`
  padding: 0;
  margin: 0;
  line-height: 1.5;
  font-family: 'Century Gothic Bold', Arial, Helvetica, sans-serif;
  font-size: 0.9rem;
  color: #4e5358;
`;

export const FormContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  width: 100%;
`;

export const InputField = styled.input`
  width: 100%;
  height: 45px;
  margin-bottom: 16px;
  border: none;
  background: #fafafa;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  font-size: 0.8rem;
  font-family: 'Century Gothic', Helvetica, Verdana, sans-serif;
  text-indent: 15px;
  color: #999;
`;

export const FormContent = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  min-height: 5%;
  background-color: #fff;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  padding: 32px;
  margin-top: 32px;
`;

export const FormFooterBtn = styled.button`
  padding: 5px 48px;
  border: none;
  background: #ffbd21;
  color: #fff;
  border-bottom: 3px solid #f7b008;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
`;
