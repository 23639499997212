const initialValues = {
  finantialResponsible: {
    name: null,
    born: null,
    phone: null,
    social_number: null,
    email: null,
    sex: null,
    postalCode: null,
    address: null,
    neightborhood: null,
    number: null,
    complement: null,
    city: null,
    parentName: null,
  },
  isFinantialResponsible: 'Sim',
  customer: {
    name: null,
    born: null,
    phone: null,
    social_number: null,
    email: null,
    sex: null,
    postalCode: null,
    address: null,
    neightborhood: null,
    number: null,
    complement: null,
    city: null,
    parentName: null,
  },
  dependents: [],
  plan: null,
  paymentType: null,
  recurrency: null,
  paymentUrl: null,
  accountBank: null,
  accountNumber: null,
  accountNumberDigit: null,
  accountAgency: null,
  accountAgencyDigit: null,
  sellerCode: null,
  status: 'Rascunho',
  documents: [],
};

export default initialValues;
