import React from 'react';
import { BrowserRouter as Router, HashRouter } from 'react-router-dom';
import Routes from './routes';
import Header from './components/Header';
import Footer from './components/Footer';
// Styles
import GlobalFonts from './fonts/fonts';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';
import './App.css';
import { Body } from './style';

function App() {
  return (
    <HashRouter hashType={'slash'}>
      <GlobalFonts />
      <Header />
      <Body>
        <Routes />
      </Body>
      <Footer />
    </HashRouter>
  );
}

export default App;
