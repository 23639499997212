import React, { useContext, useEffect, useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { Form, Row, Col } from 'react-bootstrap';

import FormFieldError from './../../../components/FormFieldError';
import FisicalContext from '../../../context/fisical';
import ArrowImage from '../../../assets/icons/arrow.svg';

import {
  WizzardBody,
  WizzardHeader,
  BtnHeader,
  WizzardContent,
  CardForm,
  WizzardFooter,
  LabelField,
  InputField,
  EmptySection,
  StepNextBtn,
  StepPreviousBtn,
  StepNextBtnTxt,
  BtnHeaderCancel,
} from '../styles';
import api from '../../../services/api';
import InputMask from 'react-input-mask';
import FormError from '../../../components/FormError';

export default function DependentsStep() {
  const { step, changeStep, updateData, formData } = useContext(FisicalContext);
  const [docs, setDocs] = useState([]);
  const [deps, setDeps] = useState([]);
  const [bornList, setBornList] = useState([]);
  const [cpfList, setCpfList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    errors,
    getValues,
    control,
  } = useForm();
  const { fields, append, prepend, remove } = useFieldArray({
    control,
    name: 'dependent',
  });

  const style = {
    width: '100%',
    height: 40,
    marginBottom: 16,
    border: 0,
    backgroundColor: '#fafafa',
    borderBottom: '1px solid #dedede',
    borderRadius: 4,
    fontSize: '0.8rem',
    fontFamily: 'Helvetica, Verdana, sans-serif',
    textIndent: 15,
    color: '#999',
  };

  /**
   * @description Update data
   * */
  const onSubmit = (data) => {
    const deps = formatDeps(fields, data);
    updateData({ dependents: deps });
    if (formData.plan.company === 'GENERICO') {
      changeStep(6);
      return
    }
    changeStep(5);
  };

  const formatDeps = (fieldsList, form) => {
    for (let i = 0, ilen = fieldsList.length; i < ilen; i++) {
      fieldsList[i].name = form.dependent[i].name;
      fieldsList[i].born = form.dependent[i].born;
      fieldsList[i].civilState = form.dependent[i].civilState;
      fieldsList[i].parentName = form.dependent[i].parentName;
      fieldsList[i].sex = form.dependent[i].sex;
      fieldsList[i].social_number = form.dependent[i].social_number;
      fieldsList[i].type = form.dependent[i].type;
      fieldsList[i].documents = docs[i];
    }
    return fieldsList;
  };

  const handleDocumentUpload = async (eid, t, i) => {
    setIsLoading(true);
    const form = new FormData();
    const docfile = document.querySelector(`#dependent${i}docPic${eid}`);
    form.append('document', docfile.files[0]);
    api
      .post('/documentUpload', form, {
        headers: {
          'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
        },
      })
      .then((response) => {
        const oldDoc = docs[i];
        const docsCopy = [...docs];
        if (oldDoc) {
          docsCopy[i][eid] = { title: t, path: response.data.filePath };
        } else {
          docsCopy[i] = [];
          docsCopy[i][eid] = { title: t, path: response.data.filePath };
        }
        setDocs(docsCopy);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!loaded) {
      let docList;
      let list = [];
      for (let i = 0, ilen = formData.dependents.length; i < ilen; i++) {
        docList = [];
        setDeps([
          ...deps,
          {
            name: formData.dependents[i].name,
            born: formData.dependents[i].born,
            social_number: formData.dependents[i].social_number,
            parentName: formData.dependents[i].parentName,
            civilState: formData.dependents[i].civilState,
            sex: formData.dependents[i].sex,
            type: formData.dependents[i].type,
          },
        ]);
        setTypeList([...typeList, formData.dependents[i].type]);
        if (formData.dependents[i].documents[0]) {
          docList[0] = formData.dependents[i].documents[0]
        }
        if (formData.dependents[i].documents[1]) {
          docList[1] = formData.dependents[i].documents[1]
        }
        if (formData.dependents[i].documents[2]) {
          docList[2] = formData.dependents[i].documents[2]
        }
        list.push(docList);
        append({
          name: formData.dependents[i].name,
          born: formData.dependents[i].born,
          social_number: formData.dependents[i].social_number,
          parentName: formData.dependents[i].parentName,
          civilState: formData.dependents[i].civilState,
          sex: formData.dependents[i].sex,
          type: formData.dependents[i].type,
        });
      }
      setDocs([...list]);
      setLoaded(true);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <WizzardBody>
      <CardForm>
        <WizzardHeader>
          <span>Dependentes</span>
          <BtnHeader
            type="button"
            onClick={() => {
              setDeps([
                ...deps,
                {
                  name: '',
                  born: '',
                  social_number: '',
                  parentName: '',
                  civilState: '',
                  sex: '',
                  type: '',
                },
              ]);
              append({
                name: '',
                born: '',
                social_number: '',
                parentName: '',
                civilState: '',
                sex: '',
                type: '',
              });
            }}>
            Adicionar um dependente
          </BtnHeader>
        </WizzardHeader>
        <WizzardContent>
          {fields.map((field, index) => (
            <fieldset key={field.id} style={{ marginBottom: 32 }}>
              <legend>Dependente - {index + 1}</legend>
              <Row>
                <Form.Group className="col-md-6">
                  <LabelField>Nome</LabelField>
                  <InputField
                    name={`dependent[${index}].name`}
                    value={deps[index] ? deps[index].name : ''}
                    onChange={(e) => {
                      let list = deps;
                      list[index]['name'] = e.target.value;
                      setDeps([...list]);
                    }}
                    ref={register({ required: true })}
                  />
                  {errors &&
                    errors.dependent &&
                    errors.dependent.length > 0 &&
                    errors.dependent[index] &&
                    errors.dependent[index].name && (
                      <FormFieldError field={errors.dependent[index].name} />
                    )}
                </Form.Group>
                <Form.Group className="col-md-6">
                  <LabelField>Tipo de Dependente</LabelField>
                  <InputField
                    as="select"
                    name={`dependent[${index}].type`}
                    value={deps[index] ? deps[index].type : ''}
                    onChange={(e) => {
                      let list = deps;
                      list[index]['type'] = e.target.value;
                      setDeps([...list]);

                      list = typeList;
                      list[index] = e.target.value;
                      setTypeList([...list]);
                    }}
                    ref={register({ required: true })}>
                    <option value="">Selecione</option>
                    <option value="Cônjuge">Cônjuge</option>
                    <option value="Filho(a)">Filho(a)</option>
                    <option value="Agregado(a)">Agregado(a)</option>
                    <option value="Pai">Pai</option>
                    <option value="Mãe">Mãe</option>
                  </InputField>
                  {errors &&
                    errors.dependent &&
                    errors.dependent.length > 0 &&
                    errors.dependent[index] &&
                    errors.dependent[index].type && (
                      <FormFieldError field={errors.dependent[index].type} />
                    )}
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className="col-md-6">
                  <LabelField>Sexo</LabelField>
                  <InputField
                    as="select"
                    name={`dependent[${index}].sex`}
                    onChange={(e) => {
                      let list = deps;
                      list[index]['sex'] = e.target.value;
                      setDeps([...list]);
                    }}
                    value={deps[index] ? deps[index].sex : ''}
                    ref={register({ required: true })}>
                    <option value="">Selecione</option>
                    <option value="Masculino">Masculino</option>
                    <option value="Feminino">Feminino</option>
                  </InputField>
                  {errors &&
                    errors.dependent &&
                    errors.dependent.length > 0 &&
                    errors.dependent[index] &&
                    errors.dependent[index].sex && (
                      <FormFieldError field={errors.dependent[index].sex} />
                    )}
                </Form.Group>
                <Form.Group className="col-md-6">
                  <LabelField>Estado civil</LabelField>
                  <InputField
                    as="select"
                    name={`dependent[${index}].civilState`}
                    onChange={(e) => {
                      let list = deps;
                      list[index]['civilState'] = e.target.value;
                      setDeps([...list]);
                    }}
                    value={deps[index] ? deps[index].civilState : ''}
                    ref={register({ required: true })}>
                    <option value="">Selecione</option>
                    <option value="Solteiro(a)">Solteiro(a)</option>
                    <option value="Casado(a)">Casado(a)</option>
                    <option value="Separado(a)">Separado(a)</option>
                    <option value="Viuvo(a)">Viuvo(a)</option>
                    <option value="Desquitado(a)">Desquitado(a)</option>
                  </InputField>
                  {errors &&
                    errors.dependent &&
                    errors.dependent.length > 0 &&
                    errors.dependent[index] &&
                    errors.dependent[index].civilState && (
                      <FormFieldError
                        field={errors.dependent[index].civilState}
                      />
                    )}
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className="col-md-6">
                  <LabelField>Data de Nascimento</LabelField>
                  <InputField
                    type="hidden"
                    name={`dependent[${index}].born`}
                    value={deps[index] ? deps[index].born : ''}
                    onChange={(e) => {
                      let list = deps;
                      list[index]['born'] = e.target.value;
                      setDeps([...list]);
                    }}
                    ref={register({
                      required: true,
                      pattern: /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
                    })}
                  />
                  <InputMask
                    type="text"
                    style={style}
                    mask="99/99/9999"
                    alwaysShowMask={false}
                    name={`dependent[${index}].born`}
                    onChange={(e) => {
                      let list = deps;
                      list[index]['born'] = e.target.value;
                      setDeps([...list]);
                    }}
                    value={deps[index] ? deps[index].born : ''}
                  />
                  {errors &&
                    errors.dependent &&
                    errors.dependent.length > 0 &&
                    errors.dependent[index] &&
                    errors.dependent[index].born &&
                    errors.dependent[index].born.type === 'required' && (
                      <FormError msg="Este campo é obrigatório" />
                    )}
                  {errors &&
                    errors.dependent &&
                    errors.dependent.length > 0 &&
                    errors.dependent[index] &&
                    errors.dependent[index].born &&
                    errors.dependent[index].born.type === 'pattern' && (
                      <FormError msg="Insira uma data válida" />
                    )}
                </Form.Group>
                <Form.Group className="col-md-6">
                  <LabelField>CPF</LabelField>
                  <InputField
                    type="hidden"
                    name={`dependent[${index}].social_number`}
                    value={deps[index] ? deps[index].social_number : ''}
                    onChange={(e) => {
                      let list = deps;
                      list[index]['social_number'] = e.target.value;
                      setDeps([...list]);
                    }}
                    ref={register({
                      required: true,
                      pattern: /^(\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2}|\d{3}\.?\d{3}\.?\d{3}-?\d{2})$/,
                    })}
                  />
                  <InputMask
                    type="text"
                    mask="999.999.999-99"
                    alwaysShowMask={false}
                    style={style}
                    name={`dependent[${index}].social_number`}
                    onChange={(e) => {
                      let list = deps;
                      list[index]['social_number'] = e.target.value;
                      setDeps([...list]);
                    }}
                    value={deps[index] ? deps[index].social_number : ''}
                  />
                  {errors &&
                    errors.dependent &&
                    errors.dependent.length > 0 &&
                    errors.dependent[index] &&
                    errors.dependent[index].social_number &&
                    errors.dependent[index].social_number.type ===
                      'required' && (
                      <FormError msg="Este campo é obrigatório" />
                    )}
                  {errors &&
                    errors.dependent &&
                    errors.dependent.length > 0 &&
                    errors.dependent[index] &&
                    errors.dependent[index].social_number &&
                    errors.dependent[index].social_number.type ===
                      'pattern' && <FormError msg="Insira um CPF válido" />}
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className="col-md-6">
                  <LabelField>Nome da Mãe</LabelField>
                  <InputField
                    name={`dependent[${index}].parentName`}
                    value={deps[index] ? deps[index].parentName : ''}
                    onChange={(e) => {
                      let list = deps;
                      list[index]['parentName'] = e.target.value;
                      setDeps([...list]);
                    }}
                    ref={register({ required: true })}
                  />
                  {errors &&
                    errors.dependent &&
                    errors.dependent.length > 0 &&
                    errors.dependent[index] &&
                    errors.dependent[index].parentName && (
                      <FormFieldError
                        field={errors.dependent[index].parentName}
                      />
                    )}
                </Form.Group>
              </Row>
              <Row>
                <Col xs={12} md={12}>
                  {(!docs[index] || !docs[index][0]) && (
                    <Form.Group>
                      <Form.File
                        accept=".png,.jpg,.jpeg,.pdf"
                        name={`dependent[${index}].docPic0`}
                        id={`dependent${index}docPic0`}
                        ref={register({ required: true })}
                        label="Documento de Identificação com CPF (Frente)"
                        custom
                        data-browse="Buscar"
                        onChange={() => {
                          handleDocumentUpload(
                            0,
                            'Documento de Identificação com CPF (Frente)',
                            index
                          );
                        }}
                      />
                      <small className="form-text text-muted">
                        CNH ou RG - Selecione um PDF ou uma imagem
                      </small>
                      {errors &&
                      errors.dependent &&
                      errors.dependent.length > 0 &&
                      errors.dependent[index] &&
                      errors.dependent[index].docPic0 && (
                        <FormFieldError
                          field={errors.dependent[index].docPic0}
                        />
                      )}
                    </Form.Group>
                  )}
                  {docs[index] && docs[index][0] && (
                    <Form.Group style={{borderBottom: '1px solid #e8e7e7'}}>
                      <span style={{ color: '#1fb71f', fontWeight: 'bold' }}>
                        CNH ou RG (Frente) <button style={{
                        fontSize: '10px',
                        padding: '0 10px',
                        lineHeight: '15px',
                        backgroundColor: '#dc3545',
                        color: 'white',
                        border: '0',
                        borderRadius: '15px',
                        marginTop: '3px',
                        display: 'block',
                        float: 'right'}} onClick={() => {
                        const docsCopy = [...docs]
                        docsCopy[index][0] = null
                        setDocs(docsCopy)
                      }}>remover</button>
                      </span>
                    </Form.Group>
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12}>
                  {(!docs[index] || !docs[index][1]) && (
                    <Form.Group>
                      <Form.File
                        accept=".png,.jpg,.jpeg,.pdf"
                        name={`dependent[${index}].docPic1`}
                        id={`dependent${index}docPic1`}
                        ref={register({ required: true })}
                        label="Documento de Identificação com CPF (Verso)"
                        custom
                        data-browse="Buscar"
                        onChange={() => {
                          handleDocumentUpload(
                            1,
                            'Documento de Identificação com CPF (Verso)',
                            index
                          );
                        }}
                      />
                      <small className="form-text text-muted">
                        CNH ou RG - Selecione um PDF ou uma imagem
                      </small>
                      {errors &&
                      errors.dependent &&
                      errors.dependent.length > 0 &&
                      errors.dependent[index] &&
                      errors.dependent[index].docPic1 && (
                        <FormFieldError
                          field={errors.dependent[index].docPic1}
                        />
                      )}
                    </Form.Group>
                  )}
                  {docs[index] && docs[index][1] && (
                    <Form.Group style={{borderBottom: '1px solid #e8e7e7'}}>
                      <span style={{ color: '#1fb71f', fontWeight: 'bold' }}>
                        CNH ou RG (Verso) <button style={{
                        fontSize: '10px',
                        padding: '0 10px',
                        lineHeight: '15px',
                        backgroundColor: '#dc3545',
                        color: 'white',
                        border: '0',
                        borderRadius: '15px',
                        marginTop: '3px',
                        display: 'block',
                        float: 'right'}} onClick={() => {
                          const docsCopy = [...docs]
                          docsCopy[index][1] = null
                          setDocs(docsCopy)
                      }}>remover</button>
                      </span>
                    </Form.Group>
                  )}
                </Col>
              </Row>
              {}
              <Row
                className={typeList[index] === 'Cônjuge' ? '' : 'not-required'}
                style={{
                  opacity: `${
                    typeList[index] === 'Cônjuge' ? '1' : '0'
                  }`,
                }}>
                <Col xs={12} md={12}>
                  {(!docs[index] || !docs[index][2]) && (
                    <Form.Group>
                      <Form.File
                        accept=".png,.jpg,.jpeg,.pdf"
                        name={`dependent[${index}].docPic2`}
                        id={`dependent${index}docPic2`}
                        ref={register({
                          required: typeList[index] === 'Cônjuge',
                        })}
                        disabled={typeList[index] !== 'Cônjuge'}
                        label="Comprovante de Casamento"
                        custom
                        data-browse="Buscar"
                        onChange={() => {
                          handleDocumentUpload(
                            2,
                            'Comprovante de Casamento',
                            index
                          );
                        }}
                      />
                      <small className="form-text text-muted">
                        Selecione um PDF ou uma imagem
                      </small>
                      {errors &&
                        errors.dependent &&
                        errors.dependent.length > 0 &&
                        errors.dependent[index] &&
                        errors.dependent[index].docPic2 && (
                          <FormFieldError
                            field={errors.dependent[index].docPic2}
                          />
                        )}
                    </Form.Group>
                  )}
                  {docs[index] && docs[index][2] && (
                    <Form.Group style={{borderBottom: '1px solid #e8e7e7'}}>
                      <span style={{ color: '#1fb71f', fontWeight: 'bold' }}>
                        Comprovante de Casamento <button style={{
                        fontSize: '10px',
                        padding: '0 10px',
                        lineHeight: '15px',
                        backgroundColor: '#dc3545',
                        color: 'white',
                        border: '0',
                        borderRadius: '15px',
                        marginTop: '3px',
                        display: 'block',
                        float: 'right'}} onClick={() => {
                        const docsCopy = [...docs]
                        docsCopy[index][2] = null
                        setDocs(docsCopy)
                      }}>remover</button>
                      </span>
                    </Form.Group>
                  )}
                </Col>
              </Row>
              <BtnHeaderCancel
                type="button"
                onClick={() => {
                  let list = docs;
                  list[index] = null;
                  setDocs([...list]);
                  list = bornList;
                  list[index] = null;
                  setBornList([...list]);
                  list = cpfList;
                  list[index] = null;
                  setCpfList([...list]);
                  list = typeList;
                  list[index] = null;
                  setTypeList([...list]);
                  remove(index);
                }}>
                Remover este dependente
              </BtnHeaderCancel>
            </fieldset>
          ))}
          {fields.length === 0 && (
            <EmptySection>
              <h6>Não possui dependentes cadastrados.</h6>
              <br />
              <br />
              <h6>
                Para adicionar dependentes clicar no botão "Adicionar um
                dependente".
              </h6>
              <h6>
                Caso não queira dependentes em seu plano clicar em salvar e
                continuar.
              </h6>
            </EmptySection>
          )}
        </WizzardContent>
        <WizzardFooter>
          <StepPreviousBtn
            onClick={() => {
              if (formData.isFinantialResponsible === 'Sim') {
                changeStep(2);
              } else {
                changeStep(3);
              }
            }}>
            Voltar
          </StepPreviousBtn>
          <StepNextBtn onClick={handleSubmit(onSubmit)} disabled={isLoading}>
            <StepNextBtnTxt>Salvar e continuar</StepNextBtnTxt>
            <img src={ArrowImage} />
          </StepNextBtn>
        </WizzardFooter>
      </CardForm>
    </WizzardBody>
  );
}
