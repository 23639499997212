import styled from 'styled-components';

export const FooterContainer = styled.section`
  width: 100%;
  padding: 32px 0;
  min-height: 20px;
  background: #f2f2f2;
  border-top: 1px solid #dedede;
`;

export const FooterText = styled.h6`
  font-family: 'Century Gothic Bold', Helvetica, Verdana, sans-serif;
  font-size: 0.7rem;
  text-align: center;
  color: #454d55;
`;
