import * as React from "react"

function QuestionMarkComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <path d="M256 0C114.833 0 0 114.833 0 256s114.833 256 256 256 256-114.833 256-256S397.167 0 256 0zm-10.667 426.667c-17.646 0-32-14.354-32-32s14.354-32 32-32 32 14.354 32 32-14.354 32-32 32zm32-130.125v34.125a10.66 10.66 0 01-10.667 10.667H224a10.66 10.66 0 01-10.667-10.667v-53.333c0-23.521 19.146-42.667 42.667-42.667s42.667-19.146 42.667-42.667-19.146-42.667-42.667-42.667-42.667 19.146-42.667 42.667v10.667a10.66 10.66 0 01-10.667 10.667H160a10.66 10.66 0 01-10.667-10.667V192c0-58.813 47.854-106.667 106.667-106.667S362.667 133.188 362.667 192c0 51.188-36.063 94.563-85.334 104.542z" />
    </svg>
  )
}

export default QuestionMarkComponent