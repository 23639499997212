import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import PlanCard from '../../widgets/PlanCard';
import api from '../../services/api';

import {
  HeaderPage,
  TitlePage,
  SubTitlePage,
  PlanPageBody,
  LoaderContainer,
} from './styles';

export default function Plans() {
  const [plans, setPlans] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  /**
   * @description Request plans on server
   * */
  const fetchPlans = async () => {
    try {
      setIsLoading(true);
      const result = await api.get('/plans');
      const { data } = result;
      setPlans(data);
      setIsLoading(false);
    } catch (err) {
      console.log(err.response);
      setIsLoading(false);
    }
  };

  /**
   * @description Render spinner component
   * @param status:boolean
   * */
  const setLoading = (status) => {
    if (status) {
      return (
        <LoaderContainer>
          <Spinner animation="grow" variant="dark" />
        </LoaderContainer>
      );
    }
  };

  /**
   * @description Render empty component
   * @param dataPlan:Array
   * */
  const setEmptyComponent = (dataPlan) => {
    if (dataPlan && dataPlan.length === 0) {
      return (
        <LoaderContainer>
          <h6>Não existe dados a serem exibidos.</h6>
        </LoaderContainer>
      );
    }
  };

  /**
   * @description Render plan card item
   * @param plan: Object
   * @param index: number
   * @todo: Improve the return, to do map loop in here
   * */
  const renderPlansList = (plan, index) => {
    return (
      <Col xs={12} md={4} key={index}>
        <PlanCard plan={plan} />
      </Col>
    );
  };

  useEffect(() => {
    fetchPlans();
  }, []);

  return (
    <PlanPageBody>
      <Container>
        <HeaderPage>
          <TitlePage>Nossos Planos</TitlePage>
          <SubTitlePage>
            Temos o plano perfeito para você. Veja logo abaixo !
          </SubTitlePage>
        </HeaderPage>

        {setLoading(isLoading)}

        {setEmptyComponent(plans)}

        {!isLoading && plans && (
          <Row>{plans.map((plan, index) => renderPlansList(plan, index))}</Row>
        )}
      </Container>
    </PlanPageBody>
  );
}
