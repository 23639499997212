import React from 'react';

import { Container, SlideTitle, SlideSubTitle } from './styles';

export default function HomeSlider() {
  return (
    <Container>
      <SlideTitle>Plano Ortodôntico Uniodonto Maceió</SlideTitle>
      <SlideSubTitle>
        Saiba como ter um sorriso perfeito com a qualidade e segurança que a
        Uniodonto proporciona.
      </SlideSubTitle>
    </Container>
  );
}
