import styled from 'styled-components';
import Bg from '../../assets/images/bg/sectionBg.png';

export const BackgroundContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  margin: 32px 0;
  padding: 32px 0;
  font-size: 16px;
  background: url(${Bg});
`;

export const Title = styled.h3`
  font-family: 'Century Gothic Bold';
  font-size: 1.3rem;
  text-align: center;
  color: #fff;
`;

export const Text = styled.p`
  font-family: 'Century Gothic';
  font-size: 0.8rem;
  line-height: 1.5;
  text-align: center;
  color: #fff;
`;

export const Btn = styled.button`
  padding: 5px 16px;
  border: none;
  background: #ffbd21;
  color: #fff;
  border-bottom: 3px solid #f7b008;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
`;
