import styled from "styled-components";
console.log(window.width);
export const MainContainer = styled.div`
  width: 100vw;
  height: 100vh;
  text-align: center;
`;

export const Body = styled.section`
  min-height: calc(100vh - 200px);
`;

export const GridContainer = styled.div`
  margin: 0 auto;
  @media (min-width: 320px) {
    max-width: 98%;
  }

  @media (min-width: 1440px) {
    max-width: 80%;
  }

  @media (min-width: 1280px) {
    max-width: 80%;
  }
`;
