import React from 'react';

import { Col } from 'react-bootstrap';
import { Label, Info } from './styles';

export default function ReviewLabel({ label, info }) {
  return (
    <Col>
      <Label>{label}</Label>
      <Info>{info}</Info>
    </Col>
  );
}
