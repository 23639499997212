import React, { useState, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { Form, Row, Col } from 'react-bootstrap';
import FisicalContext from '../../../context/fisical';
import FormError from '../../../components/FormError';
import ArrowImage from '../../../assets/icons/arrow.svg';
import {
  WizzardBody,
  WizzardHeader,
  WizzardContent,
  CardForm,
  WizzardFooter,
  LabelField,
  InputField,
  StepNextBtn,
  StepNextBtnTxt,
  StepPreviousBtn,
} from '../styles';
import api from '../../../services/api';
import { useHistory, useLocation } from 'react-router-dom';

export default function Step1() {
  const history = useHistory();
  const { search } = useLocation();
  const {
    step,
    changeStep,
    updateData,
    formData,
    updateServerData,
  } = useContext(FisicalContext);
  const { register, handleSubmit, watch, errors, getValues } = useForm();

  const [name, setName] = useState(formData.customer.name);
  const [phone, setPhone] = useState(formData.customer.phone);
  const [email, setEmail] = useState(formData.customer.email);
  const [mask, setMask] = useState('(99) 9999-9999');

  useEffect(() => {
    const urlParams = new URLSearchParams(search);
    const planId = urlParams.get('planId');
    const proposalId = urlParams.get('proposalId');
    const origin = urlParams.get('origin');
    const campaign = urlParams.get('campaign');

    if (!planId) {
      history.push('/plans');
    }

    if (proposalId) {
      api.get(`/proposals/proposal/${proposalId}`).then((response) => {
        updateServerData({ ...response.data });
        changeStep(2);
      });
      return;
    }

    if (!proposalId) {
      api.get(`/plans/plan/${planId}`).then((response) => {
        if (origin && campaign) {
          updateServerData({ plan: response.data, origin: origin, campaign: campaign });
        } else if (!origin && campaign) {
          updateServerData({ plan: response.data, campaign: campaign });
        } else if (origin && !campaign) {
          updateServerData({ plan: response.data, origin: origin });
        } else {
          updateServerData({ plan: response.data });
        }
      });
    }
  }, []);

  const style = {
    width: '100%',
    height: 40,
    marginBottom: 16,
    border: 0,
    backgroundColor: '#fafafa',
    borderBottom: '1px solid #dedede',
    borderRadius: 4,
    fontSize: '0.8rem',
    fontFamily: 'Helvetica, Verdana, sans-serif',
    textIndent: 15,
    color: '#999',
  };

  const onSubmit = () => {
    const urlParams = new URLSearchParams(search);
    const planId = urlParams.get('planId');
    const values = getValues();
    api.post(`/proposals/similar`, {...values, planID: planId}).then((response) => {
      if (response.data) {
        history.push(`/proposalFisical?planId=${planId}&proposalId=${response.data._id}`);
        updateServerData({ ...response.data });
      } else {
        updateData({ customer: values });
      }
      changeStep(2);
    });
  };

  useEffect(() => {
    populateForm(formData);
    window.scrollTo(0, 0);
  }, []);

  const populateForm = (data) => {
    setName(data.customer.name);
    setEmail(data.customer.email);
    setPhone(data.customer.phone);
  };

  return (
    <WizzardBody>
      <CardForm>
        <WizzardHeader>
          <h6>Dados Básicos</h6>
        </WizzardHeader>
        <form>
          <WizzardContent>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group>
                  <LabelField>Nome</LabelField>
                  <InputField
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    ref={register({ required: true, minLength: 5 })}
                  />
                  {errors.name?.type === 'required' && (
                    <FormError msg="Este campo é obrigatório" />
                  )}
                  {errors.name?.type === 'minLength' && (
                    <FormError msg="Insira um nome válido" />
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group>
                  <LabelField>Telefone</LabelField>
                  <InputField
                    type="hidden"
                    name="phone"
                    style={style}
                    value={phone}
                    ref={register({
                      required: true,
                      pattern: /(\(?\d{2}\)?\s)?(\d{4,5}\-\d{4})/g,
                    })}
                  />
                  <InputMask
                    type="text"
                    style={style}
                    name="phone"
                    mask={mask}
                    value={phone}
                    onChange={(e) => {
                      if (e.target.value[5] === '9') {
                        setMask("(99) 99999-9999")
                      } else {
                        setMask("(99) 9999-9999")
                      }
                      setPhone(e.target.value)
                    }}
                    ref={register({
                      required: true,
                      pattern: /(\(?\d{2}\)?\s)?(\d{4,5}\-\d{4})/g,
                    })}
                  />
                  {errors.phone?.type === 'required' && (
                    <FormError msg="Este campo é obrigatório" />
                  )}
                  {errors.phone?.type === 'pattern' && (
                    <FormError msg="Insira um telefone válido" />
                  )}
                  {errors.phone?.type === 'minLength' && (
                    <FormError msg="Insira um telefone válido" />
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group>
                  <LabelField>Email</LabelField>
                  <InputField
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    ref={register({
                      required: true,
                      pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
                    })}
                  />
                  {errors.email?.type === 'required' && (
                    <FormError msg="Este campo é obrigatório" />
                  )}
                  {errors.email?.type === 'pattern' && (
                    <FormError msg="Formato inválido" />
                  )}
                </Form.Group>
              </Col>
            </Row>
          </WizzardContent>
          <WizzardFooter>
            <span></span>
            <StepNextBtn onClick={handleSubmit(onSubmit)}>
              <StepNextBtnTxt>Salvar e continuar</StepNextBtnTxt>
              <img src={ArrowImage} />
            </StepNextBtn>
          </WizzardFooter>
        </form>
      </CardForm>
    </WizzardBody>
  );
}
