import styled from 'styled-components';

export const Label = styled.span`
  display: block;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
  font-family: 'Century Gothic Bold', Helvetica, Verdana, sans-serif;
  color: #999;
  margin-top: 16px;
`;

export const Info = styled.span`
  font-family: 'Century Gothic', Helvetica, Verdana, sans-serif;
  font-weight: bold;
  font-size: 12px;
  color: #666;
`;
