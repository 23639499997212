import React from 'react';

import { ErrorContainer, ErrorTxt } from './style';

export default function FormError({ msg }) {
  return (
    <ErrorContainer>
      <ErrorTxt>{msg}</ErrorTxt>
    </ErrorContainer>
  );
}
