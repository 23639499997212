import { createGlobalStyle } from "styled-components";

import CenturyGothic from "../assets/fonts/centuryGothic.ttf";
import CenturyGothicBold from "../assets/fonts/centuryGothic-Bold.ttf";

export default createGlobalStyle`
  @font-face {
      font-family: 'Century Gothic';
      src: local('centuryGothic'), local('centuryGothic'),
      url(${CenturyGothic}) format('ttf');
      font-weight: 300;
      font-style: normal;
  }

  @font-face {
      font-family: 'Century Gothic Bold';
      src: local('centuryGothic-Bold'), local('centuryGothic-Bold'),
      url(${CenturyGothicBold}) format('ttf');
      font-weight: 300;
      font-style: normal;
  }
`;
