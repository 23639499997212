import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import {
  PageContent,
  InfoBanner,
  InfoBannerTxt,
  FormContainer,
  FormContent,
  InputField,
  FormFooterBtn,
} from './styles';

export default function ChangePass() {
  return (
    <PageContent>
      <Container>
        <Row>
          <Col>
            <InfoBanner>
              <InfoBannerTxt>
                Seja bem vindo, por se tratar do seu primeiro acesso, é
                necessário que sua senha de acesso seja atualizada por motivos
                de segurança!
              </InfoBannerTxt>
            </InfoBanner>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormContainer>
              <FormContent>
                <InputField
                  className="animate__animated animate__flipInX"
                  type="password"
                  placeholder="Insira a nova senha"
                />
                <InputField type="password" placeholder="Repita a nova senha" />

                <FormFooterBtn>Atualizar</FormFooterBtn>
              </FormContent>
            </FormContainer>
          </Col>
        </Row>
      </Container>
    </PageContent>
  );
}
