import styled from "styled-components";

export const SectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 32px 0;
`;

export const SectionTitle = styled.h2`
  font-family: "Century Gothic Bold";
  text-align: center;
  font-size: 3rem;
  color: #891e42;
`;

export const SectionSubTitle = styled.small`
  width: 60%;
  margin-top: 1rem;
  margin-bottom: 2rem;
  text-align: center;
  line-height: 1.5;
  font-family: "Century Gothic";
  font-size: 1rem;
  color: #6a7583;
`;

export const VantageItem = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
  height: 480px;
  width: 100%;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 12px 21px rgba(0, 0, 0, 0.1);
`;

export const VanatageImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 180px;
  background: #dedede;
`;

export const VantageTitle = styled.h4`
  color: #ffbd21;
  font-family: "Century Gothic Bold";
  font-size: 1.1rem;
  margin-top: 16px;
`;

export const VantageDescription = styled.p`
  text-align: center;
  padding: 16px 32px;
  font-size: 1rem;
  font-family: "Century Gothic";
  line-height: 1.5;
`;
