import React, {useEffect} from 'react';

import { Container, Row, Col } from 'react-bootstrap';
import { FooterContainer, FooterText } from './styles';

export default function Footer() {
  /*
    useEffect(() => {
      const script = document.createElement('script');

      script.src = "https://code.jivosite.com/widget/XVeN7rpyrR";
      script.async = true;

      document.body.appendChild(script);
    }, []);
    */
    return (
    <FooterContainer>
      <Container>
        <Row>
          <Col md={4} xs={12}>
            <FooterText>
              Fone/Whatsapp: (82) 2123-3300
            </FooterText>
          </Col>
          <Col md={3} xs={12}>
            <FooterText>© 2020 Uniodonto Maceió</FooterText>
          </Col>
          <Col md={4} xs={12}>
            <FooterText>Avenida Santa Rita de Cássia, 278 - FAROL - Maceió AL</FooterText>
          </Col>
          <Col md={1} xs={12}>
            <FooterText style={{ color:'#891f42' }}>by MLTech</FooterText>
          </Col>
        </Row>
      </Container>
    </FooterContainer>
  );
}
