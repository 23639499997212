import React, { useState } from 'react';
import { Row, Col, Form, Modal, Alert } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import ReviewLabel from '../../../widgets/ReviewLabel';
import ArrowImage from '../../../assets/icons/arrow.svg';
import {
  WizzardBody,
  WizzardHeader,
  WizzardContent,
  CardForm,
  WizzardFooter,
  LabelField,
  InputField,
  Legend,
  StepNextBtn,
  StepNextBtnTxt,
  StepPreviousBtn,
} from '../styles';

export default function CreditCardStep() {
  //
  const [cardNumber, setCardNumber] = useState('');
  const [cardName, setCardName] = useState('');
  const [cardExpireDate, setCardExpireDate] = useState('');
  const [cardSecureCode, setCardSecureDate] = useState('');
  const [show, setShow] = useState(false);
  const [msg, setMsg] = useState(false);

  const style = {
    width: '100%',
    height: 40,
    marginBottom: 16,
    border: 0,
    backgroundColor: '#fafafa',
    borderBottom: '1px solid #dedede',
    borderRadius: 4,
    fontSize: '0.8rem',
    fontFamily: 'Helvetica, Verdana, sans-serif',
    textIndent: 15,
    color: '#999',
  };

  const sendPayment = () => {
    const card = {
      number: cardNumber,
      name: cardName,
      expireDate: cardExpireDate,
      secureCode: cardSecureCode,
      parcels: 1,
    };

    console.log(card);
  };

  const handleClose = () => {
    setShow(false);
    setMsg(false);
  };
  const handleShow = () => {
    setShow(true);
    setTimeout(() => {
      setShow(true);
      setMsg(true);
    }, 3000);
  };

  return (
    <WizzardBody>
      <CardForm>
        <WizzardHeader>
          <h6>Dados do Cartão de Crédito</h6>
        </WizzardHeader>
        <WizzardContent>
          <Row className="justify-content-md-center">
            <Form.Group className="col-md-6 col-12">
              <LabelField>Número do Cartão</LabelField>
              <InputMask
                style={style}
                name="cardNumber"
                mask="9999 9999 9999 9999"
                value={cardNumber}
                onChange={(e) => setCardNumber(e.target.value)}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="col-md-6 col-12">
              <LabelField>Nome do Titular</LabelField>
              <InputField
                value={cardName}
                onChange={(e) => setCardName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="col-md-3 col-12">
              <LabelField>Vencimento</LabelField>
              <InputMask
                value={cardExpireDate}
                style={style}
                name="cardExpireDate"
                mask="99/9999"
                onChange={(e) => setCardExpireDate(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="col-md-3 col-12">
              <LabelField>Cod. de segurança</LabelField>
              <InputMask
                style={style}
                name="cardSecureCode"
                mask="999"
                value={cardSecureCode}
                onChange={(e) => setCardSecureDate(e.target.value)}
              />
            </Form.Group>
          </Row>
        </WizzardContent>
        <WizzardFooter>
          <StepPreviousBtn onClick={() => console.log}>Voltar</StepPreviousBtn>
          <StepNextBtn onClick={handleShow}>
            <StepNextBtnTxt>Efetuar o pagamento</StepNextBtnTxt>

            <img src={ArrowImage} />
          </StepNextBtn>
        </WizzardFooter>
      </CardForm>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Pagamento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!msg && <span>Aguarde em quanto efetuamos o pagamento ...</span>}
          {msg && (
            <Alert variant="success">Pagamento Efetuado com sucesso !!!</Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          {msg && (
            <button variant="secondary" onClick={handleClose}>
              Sair
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </WizzardBody>
  );
}
