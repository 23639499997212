import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  background: #862344;
  padding: 10px 0 0 0;
`;

export const StatItem = styled.section`
  display: flex;
  padding: 24px 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const StatItemTitle = styled.h5`
  font-size: 1.2rem;
  font-family: 'Century Gothic Bold';
  color: #feb300;
  margin-bottom: 8px;
`;

export const StatItemDesc = styled.p`
  font-size: 0.8rem;
  font-family: 'Century Gothic';
  color: #fff;
`;
