import styled from 'styled-components';

export const Container = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 32px 16px;
  background: #fff;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
`;

export const Title = styled.h6`
  font-family: 'Century Gothic Bold', Verdana, sans-serif;
  font-weight: bold;
  font-size: 16px;
  margin: 0;
  padding: 0;
`;

export const FeaturesRow = styled.section`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 4px 0;
  border-bottom: 1px solid #f2f2f2;
`;

export const AnsColumn = styled.section`
  text-align: center;
  margin-top: 0.8rem;
`;

export const AnsItem = styled.article`
  font-family: 'Century Gothic Bold', Verdana, sans-serif;
  color: #891e42;
  font-size: 0.8rem;
  text-align: center;
`;

export const FeatureItem = styled.span`
  font-family: 'Century Gothic', Verdana, sans-serif;
  font-size: 11px;
  color: #444;
  padding: 4px 8px;
`;

export const Description = styled.p`
  font-size: 12px;
  line-height: 25px;
  color: #222;
  margin: 16px 0;
`;

export const ObsTxt = styled.p`
  font-size: 11px;
  font-weight: bold;
  line-height: 20px;
  color: #999;
  margin: 16px 0;
`;

export const TotalTxt = styled.h2`
  margin-top: 1.6rem;
  font-family: 'Century Gothic Bold', Verdana, sans-serif;
  font-size: 1.2rem;
  font-weight: bold;
  color: #891e42;
`;

export const ValuesContainer = styled.section`
  width: 100%;
  border-radius: 2px;
`;

export const ValueContainerItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px;
  margin-bottom: 8px;
  background: #fafafa;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  &.selected {
    background-color: #ffbd21;
    .price {
      color: white;
    }
    .title {
      color: #735817;
    }
  }
`;

export const ValueContainerLabel = styled.span`
  font-size: 11px;
  font-weight: bold;
  font-family: 'Century Gothic Bold', Verdana, sans-serif;
  color: #777;
  flex: 1;
`;

export const ValueContainerMoney = styled.span`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: bold;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #e28f22;
  margin-left: 8px;
  text-align: right;

  span {
    font-size: 10px;
    text-align: right;
  }
`;

export const ValuesContainerTitle = styled.h6`
  font-weight: bold;
  font-size: 14px;
`;
