import axios from "axios";
import * as env from '../env'

const api = axios.create({
  baseURL: env.API_URL,
  // baseURL: "http://localhost:3001",
});

export const notifyProposal = async (data) => {
  return axios.post(`https://www4.uniodontomaceio.com.br/ws_chatbot/gerarProposta/`, data,
    {
      headers: { Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC93d3c0LnVuaW9kb250b21hY2Vpby5jb20uYnIiLCJsb2dpbiI6IkpVQ0VMSU8iLCJpZCI6IjEyNSIsImRhdGFob3JhIjoxNTg0MzU3MjA1fQ.KFJQD9aY2jCXo2rNciz2dy-_sHq87rkFQ5G98J8ZZjU`,
        'Content-Type': 'application/json'}
    });
};

export default api;
