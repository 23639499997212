import React, { useState, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Row, Col, Modal, Alert } from 'react-bootstrap';

import FormFieldError from './../../../components/FormFieldError';
import FisicalContext from '../../../context/fisical';
import ArrowImage from '../../../assets/icons/arrow.svg';

import {
  WizzardBody,
  WizzardHeader,
  WizzardContent,
  CardForm,
  WizzardFooter,
  LabelField,
  InputField,
  StepNextBtn,
  StepPreviousBtn,
  StepNextBtnTxt,
} from '../styles';
import InputMask from 'react-input-mask';
import api from '../../../services/api';
import FormError from '../../../components/FormError';
import QuestionMarkComponent from '../../../assets/svg/QuestionMarkComponent';

export default function Step2() {
  const { step, changeStep, updateData, formData } = useContext(FisicalContext);
  const { register, handleSubmit, watch, errors, getValues } = useForm();

  const [name, setName] = useState(formData.customer.name);
  const [phone, setPhone] = useState(formData.customer.phone);
  const [email, setEmail] = useState(formData.customer.email);
  const [cpf, setCpf] = useState(formData.customer.social_number);
  const [cep, setCep] = useState(formData.customer.postalCode);
  const [neightborhood, setNeightborhood] = useState(
    formData.customer.neightborhood
  );
  const [complement, setComplement] = useState(formData.customer.complement);
  const [address, setAddress] = useState(formData.customer.address);
  const [city, setCity] = useState(formData.customer.city);
  const [sex, setSex] = useState(formData.customer.sex);
  const [parentName, setParentName] = useState(formData.customer.parentName);
  const [born, setBorn] = useState(formData.customer.born);
  const [numero, setNumero] = useState(formData.customer.number);
  const [civilState, setCivilState] = useState(formData.customer.civilState);
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);
  const [file3, setFile3] = useState(null);
  const [file4, setFile4] = useState(null);
  const [isFinantialResponsible, setIsFinantialResponsible] = useState(
    formData.isFinantialResponsible
  );
  const [openHelpModal, setOpenHelpModal] = useState(false);
  const [mask, setMask] = useState('(99) 9999-9999');

  const style = {
    width: '100%',
    height: 40,
    marginBottom: 16,
    border: 0,
    backgroundColor: '#fafafa',
    borderBottom: '1px solid #dedede',
    borderRadius: 4,
    fontSize: '0.8rem',
    fontFamily: 'Helvetica, Verdana, sans-serif',
    textIndent: 15,
    color: '#999',
  };

  const onSubmit = (data) => {
    const values = getValues();
    let docsTemp = [file1, file2];
    if (file3) {
      docsTemp.push(file3);
    }
    if (file4) {
      docsTemp.push(file4);
    }
    updateData({
      customer: { ...data, documents: docsTemp },
      isFinantialResponsible: isFinantialResponsible,
    });
    if (isFinantialResponsible === 'Não') {
      changeStep(3);
    } else {
      changeStep(4);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    populateForm(formData);
  }, []);

  const populateForm = (data) => {
    setName(data.customer.name);
    setEmail(data.customer.email);
    if (data.customer.phone.length === 11) {
      setMask('(99) 99999-9999');
    }
    setPhone(data.customer.phone);
    setCpf(data.customer.social_number);
    setSex(data.customer.sex);
    setCivilState(data.customer.civilState);
    setBorn(data.customer.born);
    setParentName(data.customer.parentName);
    setComplement(data.customer.complement);
    setCep(data.customer.postalCode);
    setAddress(data.customer.address);
    setNeightborhood(data.customer.neightborhood);
    setCity(data.customer.city);
    setNumero(data.customer.number);
    setIsFinantialResponsible(data.isFinantialResponsible);
    setFile1(
      data.customer.documents && data.customer.documents[0]
        ? data.customer.documents[0]
        : null
    );
    setFile2(
      data.customer.documents && data.customer.documents[1]
        ? data.customer.documents[1]
        : null
    );
    setFile3(
      data.customer.documents && data.customer.documents[2]
        ? data.customer.documents[2]
        : null
    );
    setFile4(
      data.customer.documents && data.customer.documents[3]
        ? data.customer.documents[3]
        : null
    );
  };

  const getAddress = async (event) => {
    const res = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
    let test = await res.json();
    setAddress(`${test.logradouro || ''}`);
    setNeightborhood(`${test.bairro || ''}`);
    setCity(`${test.localidade || ''}`);
  };

  const handleDocumentUpload = async (eid, t) => {
    const form = new FormData();
    const docfile = document.querySelector(`#documentInput${eid}`);
    form.append('document', docfile.files[0]);
    api
      .post('/documentUpload', form, {
        headers: {
          'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
        },
      })
      .then((response) => {
        switch (eid) {
          case 1:
            setFile1({ title: t, path: response.data.filePath });
            break;
          case 2:
            setFile2({ title: t, path: response.data.filePath });
            break;
          case 3:
            setFile3({ title: t, path: response.data.filePath });
            break;
          case 4:
            setFile4({ title: t, path: response.data.filePath });
            break;
        }
      });
  };

  useEffect(() => {
    if (born && born.indexOf('_') === -1) {
      const from = born.split('/');
      const birthdateTimeStamp = new Date(from[2], from[1] - 1, from[0]);
      const cur = new Date();
      const diff = cur - birthdateTimeStamp;
      const currentAge = Math.floor(diff / 31557600000);
      if (formData.plan.company === 'GENERICO'){
        setIsFinantialResponsible('Sim');
      }else {
        if (currentAge >= 18) {
          setIsFinantialResponsible('Sim');
        } else {
          setIsFinantialResponsible('Não');
        }
      }
    }
  }, [born]);

  return (
    <WizzardBody>
      <CardForm>
        <WizzardHeader>
          Titular{' '}
          <QuestionMarkComponent
            onClick={() => setOpenHelpModal(true)}
            style={{
              width: '14px',
              height: '14px',
              marginTop: '-5px',
              cursor: 'pointer',
              opacity: '0.5',
            }}
          />
        </WizzardHeader>
        <WizzardContent>
          <form>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group>
                  <LabelField>Nome</LabelField>
                  <InputField
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    ref={register({ required: true, minLength: 5 })}
                  />
                  {errors.name?.type === 'required' && (
                    <FormError msg="Este campo é obrigatório" />
                  )}
                  {errors.name?.type === 'minLength' && (
                    <FormError msg="Insira um nome válido" />
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group>
                  <LabelField>Telefone</LabelField>
                  <InputField
                    type="hidden"
                    name="phone"
                    style={style}
                    value={phone}
                    ref={register({
                      required: true,
                    })}
                  />
                  <InputMask
                    type="text"
                    style={style}
                    name="phone"
                    mask={mask}
                    value={phone}
                    onChange={(e) => {
                      if (e.target.value[5] === '9') {
                        setMask('(99) 99999-9999');
                      } else {
                        setMask('(99) 9999-9999');
                      }
                      setPhone(e.target.value);
                    }}
                    ref={register({
                      required: false,
                    })}
                  />
                  {errors.phone?.type === 'required' && (
                    <FormError msg="Este campo é obrigatório" />
                  )}
                  {errors.phone?.type === 'minLength' && (
                    <FormError msg="Insira um telefone válido" />
                  )}
                  {errors.phone?.type === 'pattern' && (
                    <FormError msg="Insira um telefone válido" />
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group>
                  <LabelField>Email</LabelField>
                  <InputField
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    ref={register({
                      required: true,
                      pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
                    })}
                  />
                  {errors.email?.type === 'required' && (
                    <FormError msg="Este campo é obrigatório" />
                  )}
                  {errors.email?.type === 'pattern' && (
                    <FormError msg="Formato inválido" />
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group>
                  <LabelField>CPF</LabelField>
                  <InputField
                    type="hidden"
                    name="social_number"
                    value={cpf}
                    ref={register({
                      required: true,
                      pattern: /^(\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2}|\d{3}\.?\d{3}\.?\d{3}-?\d{2})$/,
                    })}
                  />
                  <InputMask
                    type="text"
                    mask="999.999.999-99"
                    alwaysShowMask={false}
                    style={style}
                    name="social_number"
                    onChange={(e) => setCpf(e.target.value)}
                    value={cpf}
                    ref={register({
                      required: true,
                      pattern: /^(\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2}|\d{3}\.?\d{3}\.?\d{3}-?\d{2})$/,
                    })}
                  />
                  {errors.social_number?.type === 'required' && (
                    <FormError msg="Este campo é obrigatório" />
                  )}
                  {errors.social_number?.type === 'pattern' && (
                    <FormError msg="Insira um CPF válido" />
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group>
                  <LabelField>Sexo</LabelField>
                  <InputField
                    as="select"
                    name="sex"
                    value={sex}
                    onChange={(e) => setSex(e.target.value)}
                    ref={register({ required: true })}>
                    <option value="">Selecione</option>
                    <option value="Masculino">Masculino</option>
                    <option value="Feminino">Feminino</option>
                  </InputField>
                  <FormFieldError field={errors.sex} />
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group>
                  <LabelField>Estado Civil</LabelField>
                  <InputField
                    as="select"
                    name="civilState"
                    value={civilState}
                    onChange={(e) => setCivilState(e.target.value)}
                    ref={register({ required: true })}>
                    <option value="">Selecione</option>
                    <option value="Solteiro(a)">Solteiro(a)</option>
                    <option value="Casado(a)">Casado(a)</option>
                    <option value="Separado(a)">Separado(a)</option>
                    <option value="Viuvo(a)">Viuvo(a)</option>
                    <option value="Desquitado(a)">Desquitado(a)</option>
                  </InputField>
                  <FormFieldError field={errors.civilState} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group>
                  <LabelField>Data de Nascimento</LabelField>
                  <InputField
                    type="hidden"
                    name="born"
                    value={born}
                    ref={register({
                      required: true,
                      pattern: /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
                    })}
                  />
                  <InputMask
                    type="text"
                    style={style}
                    mask="99/99/9999"
                    alwaysShowMask={false}
                    name="born"
                    onChange={(e) => setBorn(e.target.value)}
                    value={born}
                  />
                  {errors.born?.type === 'required' && (
                    <FormError msg="Este campo é obrigatório" />
                  )}
                  {errors.born?.type === 'pattern' && (
                    <FormError msg="Insira uma data válida" />
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group>
                  <LabelField>Nome da Mãe</LabelField>
                  <InputField
                    name="parentName"
                    value={parentName}
                    onChange={(e) => setParentName(e.target.value)}
                    ref={register({ required: true })}
                  />
                  <FormFieldError field={errors.parentName} />
                </Form.Group>
              </Col>
            </Row>
            {/*<Row>*/}
            {/*  <Col xs={12} md={12}>*/}
            {/*    <Form.Group>*/}
            {/*      <Form.Check*/}
            {/*        className='check-resp'*/}
            {/*        value={isFinantialResponsible}*/}
            {/*        checked={isFinantialResponsible === 'Não'}*/}
            {/*        style={{float: 'left'}}*/}
            {/*        disabled={disableCheckFinantialResponsible}*/}
            {/*        ref={register({ required: requireCheckFinantialResponsible })}*/}
            {/*        onChange={(e) => {*/}
            {/*          if (e.target.value === 'Sim') {*/}
            {/*            setIsFinantialResponsible('Não');*/}
            {/*          } else {*/}
            {/*            setIsFinantialResponsible('Sim');*/}
            {/*          }*/}
            {/*        }}*/}
            {/*      />*/}
            {/*      <Form.Label style={{fontSize: '12px'}}>*/}
            {/*        {`${requireCheckFinantialResponsible} ${disableCheckFinantialResponsible}`} Marcar somente se o <b>RESPONSÁVEL FINANCEIRO</b> do plano for <b>DIFERENTE DO TITULAR.</b> <QuestionMarkComponent onClick={() => setOpenHelpModal(true)} style={{width: '20px', height: '20px', marginTop: '-5px', cursor: 'pointer'}} />*/}
            {/*      </Form.Label>*/}
            {/*      {errors.born?.type === 'required' && (*/}
            {/*        <FormError msg="Por favor cadastre um responsável financeiro" />*/}
            {/*      )}*/}
            {/*    </Form.Group>*/}
            {/*  </Col>*/}
            {/*</Row>*/}
            <fieldset>
              <legend>Endereço</legend>
              <Row>
                <Col xs={12} md={3}>
                  <Form.Group>
                    <LabelField>CEP</LabelField>
                    <InputField
                      name="postalCode"
                      value={cep}
                      onChange={(e) => setCep(e.target.value)}
                      onBlur={getAddress}
                      ref={register({ required: true })}
                    />
                    {errors.postalCode?.type === 'required' && (
                      <FormError msg="Este campo é obrigatório" />
                    )}
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group>
                    <LabelField>Logradouro</LabelField>
                    <InputField
                      name="address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      ref={register({ required: true })}
                    />
                    <FormFieldError field={errors.logradouro} />
                  </Form.Group>
                </Col>
                <Col xs={12} md={3}>
                  <Form.Group>
                    <LabelField>Número</LabelField>
                    <InputField
                      name="number"
                      value={numero}
                      onChange={(e) => setNumero(e.target.value)}
                      ref={register({ required: true })}
                    />
                    <FormFieldError field={errors.numero} />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={4}>
                  <Form.Group>
                    <LabelField>Cidade</LabelField>
                    <InputField
                      name="city"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      ref={register({ required: true })}
                    />
                    <FormFieldError field={errors.city} />
                  </Form.Group>
                </Col>
                <Col xs={12} md={4}>
                  <Form.Group>
                    <LabelField>Bairro</LabelField>
                    <InputField
                      name="neightborhood"
                      value={neightborhood}
                      onChange={(e) => setNeightborhood(e.target.value)}
                      ref={register({ required: true })}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={4}>
                  <Form.Group>
                    <LabelField>Complemento</LabelField>
                    <InputField
                      name="complement"
                      value={complement}
                      onChange={(e) => setComplement(e.target.value)}
                      ref={register({ required: false })}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </fieldset>
            <fieldset>
              <legend>Documentos</legend>
              <Row>
                <Col xs={12} md={12}>
                  {!file1 && (
                    <Form.Group>
                      <Form.File
                        accept=".png,.jpg,.jpeg,.pdf"
                        name="docPic1"
                        id="documentInput1"
                        ref={register({ required: true })}
                        label="Documento de Identificação com CPF (Frente)"
                        custom
                        data-browse="Buscar"
                        onChange={() => {
                          handleDocumentUpload(
                            1,
                            'Documento de Identificação com CPF (Frente)'
                          );
                        }}
                      />
                      <small className="form-text text-muted">
                        CNH ou RG - Selecione um PDF ou uma imagem
                      </small>
                      <FormFieldError field={errors.docPic1} />
                    </Form.Group>
                  )}
                  {file1 && (
                    <Form.Group style={{borderBottom: '1px solid #e8e7e7'}}>
                      <span style={{ color: '#00c800', fontWeight: 'bold', fontSize: '13px' }}>
                        CNH ou RG (Frente) <button style={{
                        fontSize: '10px',
                        padding: '0 10px',
                        lineHeight: '15px',
                        backgroundColor: '#dc3545',
                        color: 'white',
                        border: '0',
                        borderRadius: '15px',
                        marginTop: '3px',
                        display: 'block',
                        float: 'right'}} onClick={() => setFile1(null)}>remover</button>
                      </span>
                    </Form.Group>
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12}>
                  {!file2 && (
                      <Form.Group>
                        <Form.File
                          accept=".png,.jpg,.jpeg,.pdf"
                          name="docPic2"
                          id="documentInput2"
                          ref={register({ required: true })}
                          label="Documento de Identificação com CPF (Verso)"
                          custom
                          data-browse="Buscar"
                          onChange={() => {
                            handleDocumentUpload(
                              2,
                              'Documento de Identificação com CPF (Verso)'
                            );
                          }}
                        />
                        <small className="form-text text-muted">
                          CNH ou RG - Selecione um PDF ou uma imagem
                        </small>
                        <FormFieldError field={errors.docPic1} />
                      </Form.Group>
                  )}
                  {file2 && (
                    <Form.Group style={{borderBottom: '1px solid #e8e7e7'}}>
                      <span style={{ color: '#00c800', fontWeight: 'bold', fontSize: '13px' }}>
                      CNH ou RG (Verso) <button style={{
                        fontSize: '10px',
                        padding: '0 10px',
                        lineHeight: '15px',
                        backgroundColor: '#dc3545',
                        color: 'white',
                        border: '0',
                        borderRadius: '15px',
                        marginTop: '3px',
                        display: 'block',
                        float: 'right'}} onClick={() => setFile2(null)}>remover</button>
                      </span>
                    </Form.Group>
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12}>
                  {!file3 && (
                    <Form.Group>
                      <Form.File
                        accept=".png,.jpg,.jpeg,.pdf"
                        name="docPic3"
                        id="documentInput3"
                        ref={register({ required: true })}
                        label="Comprovante de Residência"
                        custom
                        data-browse="Buscar"
                        onChange={() => {
                          handleDocumentUpload(3, 'Comprovante de Residência');
                        }}
                      />
                      <small className="form-text text-muted">
                        Selecione um PDF ou uma imagem
                      </small>
                      <FormFieldError field={errors.docPic2} />
                    </Form.Group>
                  )}
                  {file3 && (
                    <Form.Group style={{borderBottom: '1px solid #e8e7e7'}}>
                      <span style={{ color: '#00c800', fontWeight: 'bold', fontSize: '13px' }}>
                        Comprovante de Residência <button style={{
                        fontSize: '10px',
                        padding: '0 10px',
                        lineHeight: '15px',
                        backgroundColor: '#dc3545',
                        color: 'white',
                        border: '0',
                        borderRadius: '15px',
                        marginTop: '3px',
                        display: 'block',
                        float: 'right'}} onClick={() => setFile3(null)}>remover</button>
                      </span>
                    </Form.Group>
                  )}
                </Col>
              </Row>
              <Row
                style={{
                  opacity: `${
                    formData.plan.person === 'Individual/Familiar' || formData.plan.company === 'GENERICO' ? '0' : '1'
                  }`,
                }}>
                <Col xs={12} md={12}>
                  {!file4 && (
                    <Form.Group>
                      <Form.File
                        accept=".png,.jpg,.jpeg,.pdf"
                        name="docPic4"
                        id="documentInput4"
                        ref={register({
                          required:
                            (formData.plan.person !== 'Individual/Familiar' && formData.plan.person !== '') ,
                        })}
                        disabled={
                          formData.plan.person === 'Individual/Familiar' || formData.plan.company === 'GENERICO'
                        }
                        label={`Comprovante ${
                          formData.plan.person === 'Servidor Público'
                            ? 'de Serviço Público'
                            : ''
                        }${
                          formData.plan.person ===
                          'Beneficiário de plano médico/hospitalar'
                            ? 'do Plano Médico/Hospitalar'
                            : ''
                        }${
                          formData.plan.person === 'Estudante'
                            ? 'de Estudante'
                            : ''
                        }`}
                        custom
                        data-browse="Buscar"
                        onChange={() => {
                          handleDocumentUpload(
                            4,
                            `Comprovante ${
                              formData.plan.person === 'Servidor Público'
                                ? 'de Serviço Público'
                                : ''
                            }${
                              formData.plan.person ===
                              'Beneficiário de plano médico/hospitalar'
                                ? 'do Plano Médico/Hospitalar'
                                : ''
                            }${
                              formData.plan.person === 'Estudante'
                                ? 'de Estudante'
                                : ''
                            }`
                          );
                        }}
                      />
                      <small className="form-text text-muted">
                        Selecione um PDF ou uma imagem
                      </small>
                      <FormFieldError field={errors.docPic3} />
                    </Form.Group>
                  )}
                  {file4 && (
                    <Form.Group style={{borderBottom: '1px solid #e8e7e7'}}>
                      <span style={{ color: '#00c800', fontWeight: 'bold', fontSize: '13px' }}>
                        {`Comprovante ${
                          formData.plan.person === 'Servidor Público'
                            ? 'de Serviço Público'
                            : ''
                        }${
                          formData.plan.person ===
                          'Beneficiário de plano médico/hospitalar'
                            ? 'do Plano Médico/Hospitalar'
                            : ''
                        }${
                          formData.plan.person === 'Estudante'
                            ? 'de Estudante'
                            : ''
                        }`}{' '}
                         <button style={{
                          fontSize: '10px',
                          padding: '0 10px',
                          lineHeight: '15px',
                          backgroundColor: '#dc3545',
                          color: 'white',
                          border: '0',
                          borderRadius: '15px',
                          marginTop: '3px',
                          display: 'block',
                          float: 'right'}} onClick={() => setFile4(null)}>remover</button>
                      </span>
                    </Form.Group>
                  )}
                </Col>
              </Row>
            </fieldset>
          </form>
        </WizzardContent>
        <WizzardFooter>
          <StepPreviousBtn />
          <StepNextBtn onClick={handleSubmit(onSubmit)}>
            <StepNextBtnTxt>Salvar e continuar</StepNextBtnTxt>

            <img src={ArrowImage} />
          </StepNextBtn>
          <Modal show={openHelpModal} onHide={() => setOpenHelpModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>O que é o titular?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <span style={{ marginBottom: '20px' }}>
                <b>Titular</b>: É o BENEFICIÁRIO do plano, podendo ou não ser o
                CONTRATANTE. Exemplo: No plano de um menor, o pai assina como
                CONTRATANTE mas o TITULAR é o filho, mesmo sendo menor de idade.
              </span>
            </Modal.Body>
          </Modal>
        </WizzardFooter>
      </CardForm>
    </WizzardBody>
  );
}
