import styled from 'styled-components';
import Slide from '../../assets/images/slides/slide.png';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  background: url(${Slide}) no-repeat center center;
  background-size: cover;

  @media (min-width: 320px) {
    height: 240px;
  }

  @media (min-width: 1440px) {
    height: 480px;
  }

  @media (min-width: 1280px) {
    height: 440px;
  }
`;

export const SlideTitle = styled.h2`
  text-align: center;
  font-family: 'Century Gothic Bold', Helvetica, Verdana, sans-serif;
  font-size: 2rem;
  color: #862344;
  margin: 0;
`;
export const SlideSubTitle = styled.h6`
  text-align: center;
  margin: 0;
  font-family: 'Century Gothic Bold', Helvetica, Verdana, sans-serif;
  font-size: 1rem;
  width: 75%;
  line-height: 1.5;
  color: #862344;
`;
