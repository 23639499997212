import React, { useState, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { Form, Row, Col } from 'react-bootstrap';
import FisicalContext from '../../../context/fisical';
import FormError from '../../../components/FormError';
import ArrowImage from '../../../assets/icons/arrow.svg';
import { PaymentSelectionOption } from './../styles';
import { useLocation, useHistory } from 'react-router-dom';
import api from '../../../services/api';

import {
  WizzardBody,
  WizzardHeader,
  WizzardContent,
  CardForm,
  WizzardFooter,
  LabelField,
  InputField,
  StepNextBtn,
  StepNextBtnTxt,
  StepPreviousBtn,
} from '../styles';

export default function PaymentSelectionStep() {
  const { step, changeStep, updateData, formData } = useContext(FisicalContext);
  const { register, handleSubmit, watch, errors, getValues } = useForm();
  const [recurrency, setRecurrency] = useState('Mensal');
  const [plan, setPlan] = useState(null);
  const [semCarencia, setSemCarencia] = useState(false);
  const { search } = useLocation();
  const [textSelection, setTextSelection] = useState('mensal');

  const idsSemCarencia = [
    '60b4df351994cc251faa9bb5',
    '60b0d0681994cc251faa9bac',
    '60b4e0401994cc251faa9bb6',
    '60b0d1441994cc251faa9bad',
    '5ec42776734b2b22dd82daa9',
    '5ec42908734b2b22dd82daad'
  ]

  const style = {
    width: '100%',
    height: 40,
    marginBottom: 16,
    border: 0,
    backgroundColor: '#fafafa',
    borderBottom: '1px solid #dedede',
    borderRadius: 4,
    fontSize: '0.8rem',
    fontFamily: 'Helvetica, Verdana, sans-serif',
    textIndent: 15,
    color: '#999',
  };

  const onSubmit = (data) => {
    updateData({ recurrency: recurrency });
    changeStep(6);
  };

  /**
   * @description Get a plan by id
   * @param id:string
   * */
  function getPlanByID() {
    const urlParams = new URLSearchParams(search);
    const planId = urlParams.get('planId');
    if (idsSemCarencia.includes(planId)) {
      setSemCarencia(true)
    }
    api.get(`/plans/plan/${planId}`).then((res) => {
      setPlan(res.data);
    });
  }

  useEffect(() => {
    setRecurrency(!formData.recurrency ? 'Mensal' : formData.recurrency);
    window.scrollTo(0, 0);
    getPlanByID();
  }, []);

  return (
    <WizzardBody>
      <CardForm>
        <WizzardHeader>
          <h6>Seleção de Pagamento</h6>
        </WizzardHeader>
        <form>
          <WizzardContent>
            <Row>
              {!semCarencia &&
                <Col xs={12} md={6}>
                  <PaymentSelectionOption
                    className={recurrency === 'Mensal' ? 'selected' : ''}>
                    <h6>Mensal</h6>
                    <p>Carências a Cumprir</p>
                    <span>24hs para Urgência e Emergência</span>
                    <span>90 dias para Canal e Protese</span>
                    <span>30 dias para demais procedimentos</span>
                    <button
                      type="button"
                      onClick={() => {
                        setRecurrency('Mensal');
                        setTextSelection('mensal');
                      }}>
                      Selecionar
                    </button>
                  </PaymentSelectionOption>
                  <h6 style={{ textAlign: 'center' }}></h6>
                </Col>
              }
              {semCarencia &&
                <Col xs={12} md={6}>
                  <PaymentSelectionOption
                    className={recurrency === 'Mensal' ? 'selected' : ''}>
                    <h6>Mensal</h6>
                    <p>Sem Carências a Cumprir</p>
                    <button
                      type="button"
                      onClick={() => {
                        setRecurrency('Mensal');
                        setTextSelection('mensal');
                      }}>
                      Selecionar
                    </button>
                  </PaymentSelectionOption>
                  <h6 style={{ textAlign: 'center' }}>

                  </h6>
                </Col>
              }
              <Col xs={12} md={6}>
                <PaymentSelectionOption
                  className={recurrency === 'Anual' ? 'selected' : ''}>
                  <h6>Anual</h6>
                  <p>Sem Carências a Cumprir</p>
                  <button
                    type="button"
                    onClick={() => {
                      setRecurrency('Anual');
                      setTextSelection('anual');
                    }}>
                    Selecionar
                  </button>
                </PaymentSelectionOption>
                <h6 style={{ textAlign: 'center' }}>

                </h6>
              </Col>
            </Row>
            <p
              style={{
                fontSize: 12,
                color: '#666',
                marginTop: 32,
              }}>
              {textSelection && textSelection === 'anual' && (
                <span>
                  A forma de pagamento selecionada foi <strong>ANUAL</strong>.
                  Será cobrado o valor total, podendo ser pago no cartão de
                  crédito em até 12x sem juros ou em um único pagamento, através
                  de boleto ou transferência bancária.
                </span>
              )}
              {textSelection && textSelection === 'mensal' && (
                <span>
                  A forma de pagamento selecionada foi <strong>MENSAL</strong>.
                  Será cobrado o valor mensal
                  referente ao plano selecionado.
                </span>
              )}
            </p>
          </WizzardContent>
          <WizzardFooter>
            <StepPreviousBtn onClick={() => changeStep(4)}>
              Voltar
            </StepPreviousBtn>
            <StepNextBtn
              onClick={handleSubmit(onSubmit)}
              disabled={recurrency === null}>
              <StepNextBtnTxt>Salvar e continuar</StepNextBtnTxt>

              <img src={ArrowImage} />
            </StepNextBtn>
          </WizzardFooter>
        </form>
      </CardForm>
    </WizzardBody>
  );
}
