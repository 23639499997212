import styled from 'styled-components';

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 3px;
`;

export const ErrorTxt = styled.span`
  color: #d69b9b;
  font-size: 11px;
  font-weight: 700;
`;
