import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

// Pages
import Home from './pages/Home';
import Proposal from './pages/Proposal';
import ProposalFisical from './pages/FisicalProposal';
import Plans from './pages/Plans';
import Login from './pages/Login';
import ChangePass from './pages/ChangePass';

export default function MainRoutes() {
  return (
    <Switch>
      <Route path="/plans">
        <Plans />
      </Route>
      <Route path="/proposal">
        <Proposal />
      </Route>
      <Route path="/proposalFisical">
        <ProposalFisical />
      </Route>
      <Route path="/login">
        <Login />
      </Route>
      <Route path="/changePass">
        <ChangePass />
      </Route>
      <Route path="/">
        <Home />
      </Route>
    </Switch>
  );
}
