import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { GridContainer } from '../../style';
import { Container, StatItem, StatItemTitle, StatItemDesc } from './styles';

export default function HomeStats() {
  return (
    <Container>
      <GridContainer>
        <Row>
          <Col xs={12} md={4}>
            <StatItem>
              <StatItemTitle>Mais de 3.000.000</StatItemTitle>
              <StatItemDesc>Beneficiários em todo o Brasil.</StatItemDesc>
            </StatItem>
          </Col>
          <Col xs={12} md={4}>
            <StatItem>
              <StatItemTitle>Aproximadamente 22.000</StatItemTitle>
              <StatItemDesc>Cirurgiões Dentistas Cooperados</StatItemDesc>
            </StatItem>
          </Col>
          <Col xs={12} md={4}>
            <StatItem>
              <StatItemTitle>Possuimos 99</StatItemTitle>
              <StatItemDesc>De aprovação junto a ANS</StatItemDesc>
            </StatItem>
          </Col>
        </Row>
      </GridContainer>
    </Container>
  );
}
