import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
// Components
import HomeSlider from "../../components/HomeSlider";
import HomeStats from "../../components/HomeStats";
import HomeVantages from "../../components/HomeVantages";
import HomeText from "../../components/HomeText";

export default class Home extends Component {
  render() {
    document.location.replace("https://www.uniodontomaceio.com.br");
    return (
      <>
        <HomeSlider />
        <HomeStats />
        <HomeVantages />
        <HomeText />
      </>
    );
  }
}
