import styled from 'styled-components';

export const Page = styled.section`
  display: flex;
  justify-content: center;
  height: calc(100vh - 190px);
`;

export const FormContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 330px;
  background: #fff;
  border: 1px solid #dedede;
  border-radius: 6px;
  margin-top: 20%;
`;

export const FormHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 64px;
  border-bottom: 1px solid #dedede;
`;

export const FormHeaderTitle = styled.h5`
  text-align: center;
  font-family: 'Century Gothic Bold', Helvetica, Verdana sans-serif;
  font-size: 14px;
  margin: 0;
`;

export const FormBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 16px;
  flex: 1;
`;

export const FormFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 16px;
`;

export const InputField = styled.input`
  width: 100%;
  height: 45px;
  margin-bottom: 16px;
  border: none;
  background: #fafafa;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  font-size: 0.8rem;
  font-family: 'Century Gothic', Helvetica, Verdana, sans-serif;
  text-indent: 15px;
  color: #999;
`;

export const FormFooterBtn = styled.button`
  padding: 5px 48px;
  border: none;
  background: #ffbd21;
  color: #fff;
  border-bottom: 3px solid #f7b008;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
`;
