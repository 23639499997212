import React from 'react';

import { Container, Subtitle, List, ListItem } from './styles';

export default function PjDocInfo() {
  return (
    <Container>
      <h5 style={{ textAlign: 'center', color: '#a91c1c' }}>Deve ser enviado a foto ou PDF dos documentos listados abaixo:</h5>
      <Subtitle>Titular:</Subtitle>
      <List>
        <ListItem>Identidade ou CNH</ListItem>
        <ListItem>Comprovante de residência</ListItem>
      </List>

      <Subtitle>Dependente Legal (Filho)</Subtitle>
      <List>
        <ListItem>Identidade</ListItem>
      </List>

      <Subtitle>Dependente Legal (Esposo(a)</Subtitle>
      <List>
        <ListItem>Identidade ou CNH</ListItem>
      </List>

      <Subtitle>Dependente Agregado</Subtitle>
      <List>
        <ListItem>Identidade ou CNH</ListItem>
      </List>
    </Container>
  );
}
