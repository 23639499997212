import React from 'react';
import { ErrorText } from './styles';

export default function FormFieldError({ field }) {
  console.log(field);
  return (
    <div>
      {field && (
        <>
          {field.type === 'required' && (
            <ErrorText>Este campo é requerido</ErrorText>
          )}
        </>
      )}
    </div>
  );
}
