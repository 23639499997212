import React from 'react';

import { Container, Title, Subtitle, List, ListItem } from './styles';

export default function PersonDocInfo() {
  return (
    <Container>
      <h5 style={{ textAlign: 'center', color: '#a91c1c' }}>Deve ser enviado a foto ou PDF dos documentos listados abaixo:</h5>
      <Subtitle>Responsável Financeiro / Titular:</Subtitle>
      <List>
        <ListItem>Identidade ou CNH</ListItem>
        <ListItem>Comprovante de residência</ListItem>
      </List>

      <Subtitle>Dependente Legal (Filho)</Subtitle>
      <List>
        <ListItem>Identidade</ListItem>
        <ListItem>Certidão de Nascimento</ListItem>
      </List>

      <Subtitle>Dependente Legal (Esposo(a))</Subtitle>
      <List>
        <ListItem>Identidade ou CNH</ListItem>
        <ListItem>
          Certidão de Casamento ou Declaração de união estável
        </ListItem>
      </List>

      <Subtitle>Dependente Agregado</Subtitle>
      <List>
        <ListItem>Identidade ou CNH</ListItem>
      </List>

      <Subtitle>
        Obs.: Em caso de plano estudantil, anexar documento que comprove
      </Subtitle>
      <Subtitle>
        Obs.: Em caso de plano fucionario publico, anexar documento que comprove
      </Subtitle>
    </Container>
  );
}
