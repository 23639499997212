import styled from 'styled-components';

export const Text = styled.p`
  padding: 16px;
  font-family: 'Century Gothic Bold', Helvetica, Verdana, sans-serif;
  font-size: 12px;
  color: #e28f22;
  background: #ffefc7;
  border-radius: 4px;
  margin-top: 8px;
`;
