import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  SectionContainer,
  SectionTitle,
  SectionSubTitle,
  VanatageImageContainer,
  VantageItem,
  VantageTitle,
  VantageDescription,
} from "./styles";

export default function HomeVantages() {
  return (
    <Container>
      <SectionContainer>
        <SectionTitle>Porque contratar um de nossos planos</SectionTitle>
        <SectionSubTitle>
          Fashion axe migas portland kitsch kale chips, YOLO DIY crucifix. Pork
          belly slow-carb copper mug kombucha
        </SectionSubTitle>
      </SectionContainer>
      <Row>
        <Col xs={12} md={4}>
          <VantageItem>
            <VanatageImageContainer></VanatageImageContainer>
            <VantageTitle>Cobertura nacional</VantageTitle>
            <VantageDescription>
              Você pode ter os nossos serviços em qualquer parte do Brasil
            </VantageDescription>
          </VantageItem>
        </Col>
        <Col xs={12} md={4}>
          <VantageItem>
            <VanatageImageContainer></VanatageImageContainer>
            <VantageTitle>Inúmeras especialidades</VantageTitle>
            <VantageDescription>
              Abrange as especialidades de Diagnóstico, Dentística, Urgência/Emergência,
              Prevenção, Odontopediatria, Periodontia, Endodontia, Radiologia, Cirurgia e
              Prótese.
            </VantageDescription>
          </VantageItem>
        </Col>
        <Col xs={12} md={4}>
          <VantageItem>
            <VanatageImageContainer></VanatageImageContainer>
            <VantageTitle>Plantão 24 horas</VantageTitle>
            <VantageDescription>
              Atendimento de urgência e emergência 24 horas por dia, todos os dias, no
              Hospital Unimed.
            </VantageDescription>
          </VantageItem>
        </Col>
        <Col xs={12} md={4}>
          <VantageItem>
            <VanatageImageContainer></VanatageImageContainer>
            <VantageTitle>Parceria com a Unimed</VantageTitle>
            <VantageDescription>
              Você pode integrar o pagamento da Uniodonto no mesmo boleto da Unimed,
              com diferencial nos preços e carências.
            </VantageDescription>
          </VantageItem>
        </Col>
        <Col xs={12} md={4}>
          <VantageItem>
            <VanatageImageContainer></VanatageImageContainer>
            <VantageTitle>Carência liberada</VantageTitle>
            <VantageDescription>
              Carências totalmente liberadas de imediato para a opção de pagamento no
              cartão de crédito.
            </VantageDescription>
          </VantageItem>
        </Col>
        <Col xs={12} md={4}>
          <VantageItem>
            <VanatageImageContainer></VanatageImageContainer>
            <VantageTitle>Descontos especiais</VantageTitle>
            <VantageDescription>
              Descontos especiais para servidores públicos, estudantes e beneficiários de
              Plano de Saúde (Unimed e outros).
            </VantageDescription>
          </VantageItem>
        </Col>
      </Row>
    </Container>
  );
}
