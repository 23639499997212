import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { Row, Col, Spinner } from 'react-bootstrap';

import api from '../../services/api';

import PagSeguro from '../../assets/images/logo-pagseguro.png';

import {
  Container,
  Title,
  Description,
  FeaturesRow,
  FeatureItem,
  AnsColumn,
  AnsItem,
  ObsTxt,
  TotalTxt,
  ValuesContainer,
  ValuesContainerTitle,
  ValueContainerItem,
  ValueContainerLabel,
  ValueContainerMoney,
} from './styles';

export default function SelectedPlanDescription({
  proposal,
  planId,
  calcProposalAmount,
}) {
  //
  const [plan, setPlan] = useState(null);
  const [loading, setLoading] = useState(false);

  /**
   * @description Get a plan by id
   * @param id:string
   * */
  function getPlanByID(planID) {
    setLoading(true);
    api.get(`/plans/plan/${planID}`).then((res) => {
      setPlan(res.data);
      console.log(res.data);
      setLoading(false);
    });
  }

  /**
   * @description Render spinner component
   * @param status:boolean
   * */
  function showLoading(status) {
    if (status) {
      return <Spinner animation="grow" variant="dark" />;
    }
  }

  useEffect(() => {
    getPlanByID(planId);
  }, []);

  return (
    <Container>
      {showLoading(loading)}
      {!loading && plan && (
        <>
          <Title style={{textAlign: 'center'}}>{plan.name}</Title>
          <FeaturesRow>
            <FeatureItem>{plan.company === 'GENERICO' ? '(Empresarial)' : (plan.person)}</FeatureItem>
          </FeaturesRow>
          <AnsColumn>
            <AnsItem>Nº de Registro na ANS: {plan.ans ? plan.ans : ''}</AnsItem>
            <AnsItem>Abrangência: Nacional</AnsItem>
          </AnsColumn>
          <Description>{plan.description}</Description>
          { plan.company !== 'GENERICO' && (
            <ValuesContainer>
              <ValuesContainerTitle>Valores</ValuesContainerTitle>
              <ValueContainerItem
                className={
                  proposal.dependents && proposal.dependents.length === 0
                    ? 'selected'
                    : ''
                }>
                <ValueContainerLabel className="title">
                  Apenas o titular
                </ValueContainerLabel>
                <ValueContainerMoney className="price">
                  <NumberFormat
                    fixedDecimalScale={true}
                    decimalScale={2}
                    value={plan.amount_1_life}
                    displayType={'text'}
                    thousandSeparator="."
                    isNumericString={true}
                    decimalSeparator={','}
                    prefix={'R$ '}
                  />
                </ValueContainerMoney>
              </ValueContainerItem>
              <ValueContainerItem
                className={
                  proposal.dependents && proposal.dependents.length === 1
                    ? 'selected'
                    : ''
                }>
                <ValueContainerLabel className="title">
                  O titular + 01 dependente
                </ValueContainerLabel>
                <ValueContainerMoney className="price">
                  <NumberFormat
                    fixedDecimalScale={true}
                    decimalScale={2}
                    value={plan.amount_2_life}
                    displayType={'text'}
                    thousandSeparator="."
                    isNumericString={true}
                    decimalSeparator={','}
                    prefix={'R$ '}
                  />
                  <span>(por pessoa)</span>
                </ValueContainerMoney>
              </ValueContainerItem>
              <ValueContainerItem
                className={
                  proposal.dependents && proposal.dependents.length === 2
                    ? 'selected'
                    : ''
                }>
                <ValueContainerLabel className="title">
                  O titular + 02 dependentes
                </ValueContainerLabel>
                <ValueContainerMoney className="price">
                  <NumberFormat
                    fixedDecimalScale={true}
                    decimalScale={2}
                    value={plan.amount_3_life}
                    displayType={'text'}
                    thousandSeparator="."
                    isNumericString={true}
                    decimalSeparator={','}
                    prefix={'R$ '}
                  />
                  <span>(por pessoa)</span>
                </ValueContainerMoney>
              </ValueContainerItem>
              <ValueContainerItem
                className={
                  proposal.dependents && proposal.dependents.length >= 3
                    ? 'selected'
                    : ''
                }>
                <ValueContainerLabel className="title">
                  O titular + 03 ou mais dependentes
                </ValueContainerLabel>
                <ValueContainerMoney className="price">
                  <NumberFormat
                    fixedDecimalScale={true}
                    decimalScale={2}
                    value={plan.amount_4_life}
                    displayType={'text'}
                    thousandSeparator="."
                    isNumericString={true}
                    decimalSeparator={','}
                    prefix={'R$ '}
                  />
                  <span>(por pessoa)</span>
                </ValueContainerMoney>
              </ValueContainerItem>
            </ValuesContainer>
          )}
          {!loading && plan && proposal.recurrency && (
            <TotalTxt>
              Total{` ${proposal.recurrency} : `}
              <NumberFormat
                fixedDecimalScale={true}
                decimalScale={2}
                value={calcProposalAmount(proposal)}
                displayType={'text'}
                thousandSeparator="."
                isNumericString={true}
                decimalSeparator={','}
                prefix={'R$ '}
              />
            </TotalTxt>
          )}
          {plan && proposal.obs && <ObsTxt>Obs.: {plan.obs}</ObsTxt>}
        </>
      )}
      {/* <img src={PagSeguro} alt="" height="150px" /> */}
    </Container>
  );
}
