import * as Yup from 'yup';

const validations = Yup.object().shape({
  finantialResponsible: Yup.object().shape({
    name: Yup.string().required('Informe o nome do responsável'),
    born: Yup.string().required('Este campo precisa ser preenchido'),
    phone: Yup.string().required('Este campo precisa ser preenchido'),
    social_number: Yup.string().required('Este campo precisa ser preenchido'),
    email: Yup.string().required('Este campo precisa ser preenchido'),
    sex: Yup.string().required('Este campo precisa ser preenchido'),
    postalCode: Yup.string().required('Este campo precisa ser preenchido'),
    address: Yup.string().required('Este campo precisa ser preenchido'),
    neightborhood: Yup.string().required('Este campo precisa ser preenchido'),
    number: Yup.string().required('Este campo precisa ser preenchido'),
    complement: Yup.string().required('Este campo precisa ser preenchido'),
    city: Yup.string().required('Este campo precisa ser preenchido'),
  }),
  customer: Yup.object().shape({
    name: Yup.string().required('Este campo precisa ser preenchido'),
    born: Yup.string().required('Este campo precisa ser preenchido'),
    phone: Yup.string().required('Este campo precisa ser preenchido'),
    social_number: Yup.string().required('Este campo precisa ser preenchido'),
    email: Yup.string().required('Este campo precisa ser preenchido'),
    sex: Yup.string().required('Este campo precisa ser preenchido'),
    postalCode: Yup.string().required('Este campo precisa ser preenchido'),
    address: Yup.string().required('Este campo precisa ser preenchido'),
    neightborhood: Yup.string().required('Este campo precisa ser preenchido'),
    number: Yup.string().required('Este campo precisa ser preenchido'),
    complement: Yup.string().required('Este campo precisa ser preenchido'),
    city: Yup.string().required('Este campo precisa ser preenchido'),
  }),
  plan: Yup.string().required('Este campo precisa ser preenchido'),
  paymentType: Yup.string().required('Este campo precisa ser preenchido'),
  recurrency: Yup.string().required('Este campo precisa ser preenchido'),
  paymentUrl: Yup.string().required('Este campo precisa ser preenchido'),
  accountBank: Yup.string().required('Este campo precisa ser preenchido'),
  accountNumber: Yup.string().required('Este campo precisa ser preenchido'),
  accountNumberDigit: Yup.string().required(
    'Este campo precisa ser preenchido'
  ),
  accountAgency: Yup.string().required('Este campo precisa ser preenchido'),
  accountAgencyDigit: Yup.string().required(
    'Este campo precisa ser preenchido'
  ),
});

export default validations;
