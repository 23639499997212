import styled from 'styled-components';

export const HeaderContainer = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  padding: 16px 0;
  background-color: #891e42;
`;

export const LogoHeader = styled.img`
  width: 140px;
`;

export const HeaderInforContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;

export const NavItem = styled.span`
  color: #f2f2f2;
  font-family: 'Century Gothic Bold', Helvetica, Verdana;
  border: none;
`;
