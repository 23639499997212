import React from 'react';
import {
  Page,
  FormContainer,
  FormHeader,
  FormBody,
  FormFooter,
  FormHeaderTitle,
  FormFooterBtn,
  InputField,
} from './styles';

export default function Login() {
  return (
    <Page>
      <FormContainer>
        <FormHeader>
          <FormHeaderTitle>Área do Cliente</FormHeaderTitle>
        </FormHeader>
        <FormBody>
          <InputField type="email" placeholder="Email" />
          <InputField type="password" placeholder="Senha" />
        </FormBody>
        <FormFooter>
          <FormFooterBtn>Entrar</FormFooterBtn>
        </FormFooter>
      </FormContainer>
    </Page>
  );
}
