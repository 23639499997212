import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  padding: 16px;
  background: #f2f2f2;
  margin-bottom: 16px;
  border-radius: 4px;
`;

export const Title = styled.h4`
  font-size: 12px;
  font-family: 'Century Gothic Bold', Helvetica, Verdana, sans-serif;
  color: #333;
`;

export const Subtitle = styled.h6`
  font-size: 11px;
  font-family: 'Century Gothic Bold', Helvetica, Verdana, sans-serif;
  color: #666;
`;

export const List = styled.ul`
  padding: 8px 32px;
`;

export const ListItem = styled.li`
  padding: 0%;
  font-size: 11px;
  font-family: 'Century Gothic Bold', Helvetica, Verdana, sans-serif;
  color: #333;
`;
