import React from 'react';
import { Container } from 'react-bootstrap';

import { FisicalProvider } from '../../context/fisical';
import Steps from './components/steps';

export default function FisicalProposal() {
  return (
    <FisicalProvider>
      <Container>
        <Steps />
      </Container>
    </FisicalProvider>
  );
}
