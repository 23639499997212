import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useLocation, useHistory } from 'react-router-dom';
import InputMask from 'react-input-mask';
import NumberFormat from 'react-number-format';

import PersonDocInfo from '../../components/PersonDocInfo';
import PjDocInfo from '../../components/PjDocInfo';
import StepInfo from '../../components/StepInfo';
import validationSchema from '../../utils/formValidations';
import initialValues from '../../utils/formValues';

import UploadImg from '../../assets/icons/upload.svg';
import ErrorImg from '../../assets/icons/error.svg';

import DocAditivo from '../../assets/docs/aditivo.pdf';
import DocProposta from '../../assets/docs/proposta.pdf';

import {
  Container,
  Form,
  Row,
  Col,
  Button,
  ButtonGroup,
  Modal,
  Alert,
} from 'react-bootstrap';

import {
  HeaderPage,
  TitlePage,
  SubTitlePage,
  CardForm,
  BtnWizzard,
  WizzardBody,
  WizzardHeader,
  InputField,
  InpurFieldMasked,
  LabelField,
  Legend,
  WizzardFooter,
  StepNextBtn,
  StepPreviousBtn,
  BtnHeader,
  ResumeTitle,
  ResumeItem,
  ResumeItemTitle,
  ResumeItemSubTitle,
  ResumeItemDashed,
  ResumeItemTotal,
  ResumeItemSubTotal,
  WizzardContent,
  BtnHeaderCancel,
} from './styles';
import api from '../../services/api';
import { ListItem } from '../../components/PersonDocInfo/styles';

const dependentsModel = {
  name: null,
  born: null,
  social_number: null,
  parentName: null,
  sex: null,
  type: null,
};

export default function Proposal() {
  const [doc, setDoc] = useState(null);
  const [accept, setAccept] = useState(false);
  const [docName, setDocName] = useState('Titular - Identidade ou CNH');
  const [proposalID, setProposalID] = useState(null);
  const [formValues, setFormValues] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [planType, setPlanType] = useState(null);
  const [errors, setErrors] = useState({});
  const [dependentError, setDependentError] = useState(false);
  const [seller, setSeller] = useState(false);
  const [isAllDependentsOK, setIsAllDependentsOK] = useState(false);
  const [sendingDocument, setSendingDocument] = useState(false);

  const [step1, setStep1] = useState(true);
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);
  const [step4, setStep4] = useState(false);
  const [step5, setStep5] = useState(false);
  const [step6, setStep6] = useState(false);
  const [zipcode, setZipcode] = useState('');
  const history = useHistory();

  const { search } = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(search);
    const planId = urlParams.get('planId');
    const proposalId = urlParams.get('proposalId');

    if (planId) {
      api.get(`/plans/plan/${planId}`).then((response) => {
        formik.setFieldValue('recurrency', 'Mensal');
        if (response.data.type === 'Empresarial') {
          formik.setFieldValue('paymentType', 'Empresa');
          setPlanType('Empresarial');
        }
        formik.setFieldValue('plan', response.data);
      });
    } else {
      history.push('/plans');
    }

    if (proposalId) {
      api.get(`/proposals/proposal/${proposalId}`).then((response) => {
        formik.setValues(response.data);
        setFormValues(response.data);
        setProposalID(response.data['_id']);
        if(response.data['sellerCode'] === '' || response.data['sellerCode'] === null || response.data['sellerCode'] === undefined){
          setSeller(false);
        }else{
          setSeller(true);
        }
        if(response.data.status === "Pendente"){
          setIsAllDependentsOK(true);
        }
      });
    }
  }, []);

  const style = {
    width: '100%',
    height: 40,
    marginBottom: 16,
    border: 0,
    backgroundColor: '#fafafa',
    borderBottom: '1px solid #dedede',
    borderRadius: 4,
    fontSize: '0.8rem',
    fontFamily: 'Helvetica, Verdana, sans-serif',
    textIndent: 15,
    color: '#999',
  };

  const validate = (values) => {
    const err = {};

    if (!values.customer.name) {
      err.customerName = '*';
    } else {
      err.customerName = '';
    }
    if (!values.customer.phone) {
      err.customerPhone = '*';
    } else {
      err.customerPhone = '';
    }
    if (!values.customer.born) {
      err.customerBorn = '*';
    } else {
      err.customerBorn = '';
    }
    if (!values.customer.social_number) {
      err.customerSocialNumber = '*';
    } else {
      err.customerSocialNumber = '';
    }
    if (!values.customer.sex) {
      err.customerSex = '*';
    } else {
      err.customerSex = '';
    }
    if (!values.customer.parentName) {
      err.customerParentName = '*';
    } else {
      err.customerParentName = '';
    }
    if (!values.customer.postalCode) {
      err.customerPostalCode = '*';
    } else {
      err.customerPostalCode = '';
    }
    if (!values.customer.neightborhood) {
      err.customerNeightborhood = '*';
    } else {
      err.customerNeightborhood = '';
    }
    if (!values.customer.address) {
      err.customerAddress = '*';
    } else {
      err.customerAddress = '';
    }
    if (!values.customer.city) {
      err.customerCity = '*';
    } else {
      err.customerCity = '';
    }
    if (!values.customer.number) {
      err.customerNumber = '*';
    } else {
      err.customerNumber = '';
    }
    if (!values.recurrency) {
      err.recurrency = '*';
    } else {
      err.recurrency = '';
    }
    if (!values.paymentType) {
      err.paymentType = '*';
    } else {
      err.paymentType = '';
    }
    if (!values.accountBank) {
      err.accountBank = '*';
    } else {
      err.accountBank = '';
    }
    if (!values.accountNumber) {
      err.accountNumber = '*';
    } else {
      err.accountNumber = '';
    }
    if (!values.accountNumberDigit) {
      err.accountNumberDigit = '*';
    } else {
      err.accountNumberDigit = '';
    }
    if (!values.accountAgency) {
      err.accountAgency = '*';
    } else {
      err.accountAgency = '';
    }
    if (!values.accountAgencyDigit) {
      err.accountAgencyDigit = '*';
    } else {
      err.accountAgencyDigit = '';
    }
    return err;
  };

  // Form Settings
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      console.log(values);
      setErrors(validate(values));
      if (formik.values.status === 'Pendente' || formik.values.status === 'Aprovada' || formik.values.status === 'Conluída') {
        return;
      }
      if (formik.values.plan && formik.values.plan.type === 'Empresarial') {
        formik.setFieldValue('recurrency', 'Mensal');
      }
      if (formik.values.dependents.length === 0) {
        setIsAllDependentsOK(true);
      } else {
        validateDependents(values.dependents);
      }
      const totalAmount = calcProposalAmount();
      setFormValues({
        ...values,
        amount: totalAmount,
      });
      values = adjustMaskInputs(values);
      if (proposalID) {
        api.put('/proposals', {
          _id: proposalID,
          ...values,
          amount: totalAmount,
        });
      } else {
        api
          .post('/proposals', {
            ...values,
            amount: totalAmount,
          })
          .then((response) => {
            setProposalID(response.data['_id']);
          });
      }
    },
  });

  const adjustMaskInputs = (values) => {
    if (values.customer && values.customer.social_number) {
      values.customer.social_number =
        ('customer.social_number',
        values.customer.social_number.replace('-', '').replace('.', ''));
    }
    if (values.customer && values.customer.phone) {
      values.customer.phone =
        ('customer.phone',
        values.customer.phone
          .replace(' ', '')
          .replace('-', '')
          .replace('(', '')
          .replace(')', ''));
    }
    if (values.isFinantialResponsible !== 'Sim') {
      if (values.finantialResponsible) {
        if (values.finantialResponsible.social_number) {
          values.finantialResponsible.social_number =
            ('customer.social_number',
            values.finantialResponsible.social_number
              .replace('-', '')
              .replace('.', ''));
        }
        if (values.finantialResponsible.phone) {
          values.finantialResponsible.phone =
            ('customer.phone',
            values.finantialResponsible.phone
              .replace(' ', '')
              .replace('-', '')
              .replace('(', '')
              .replace(')', ''));
        }
      }
    }
    if (values.dependents && values.dependents.length > 0) {
      for (let i = 0, ilen = values.dependents.length; i < ilen; i++) {
        if (values.dependents[i].social_number) {
          values.dependents[i].social_number = values.dependents[
            i
          ].social_number
            .replace('-', '')
            .replace('.', '');
        }
      }
    }
    return values;
  };

  const validateDependents = (depList) => {
    let obj;
    let isOK = false;
    for (let i = 0, ilen = depList.length; i < ilen; i++) {
      obj = depList[i];
      if (
        obj['name'] !== null &&
        obj['name'] !== '' &&
        obj['born'] !== null &&
        obj['born'] !== '' &&
        obj['social_number'] !== null &&
        obj['social_number'] !== '' &&
        obj['parentName'] !== null &&
        obj['parentName'] !== '' &&
        obj['sex'] !== null &&
        obj['sex'] !== '' &&
        obj['type'] !== null &&
        obj['type'] !== ''
      ) {
        isOK = true;
      } else {
        isOK = false;
        break;
      }
    }
    if (isOK) {
      setIsAllDependentsOK(true);
    } else {
      setIsAllDependentsOK(false);
    }
  };

  const calcProposalAmount = () => {
    let amount = 0;
    if (formik.values.plan) {
      if (formik.values.plan.type === 'Empresarial') {
        if (
          formik.values.plan &&
          (!formik.values.dependents || formik.values.dependents.length === 0)
        ) {
          amount = parseFloat(formik.values.plan.amount_1_life);
        } else if (
          formik.values.plan &&
          formik.values.dependents &&
          formik.values.dependents.length === 1
        ) {
          amount =
            parseFloat(formik.values.plan.amount_2_life) +
            parseFloat(formik.values.plan.amount_1_life);
        } else if (
          formik.values.plan &&
          formik.values.dependents &&
          formik.values.dependents.length === 2
        ) {
          amount =
            parseFloat(formik.values.plan.amount_3_life) * 2 +
            parseFloat(formik.values.plan.amount_1_life);
        } else if (
          formik.values.plan &&
          formik.values.dependents &&
          formik.values.dependents.length === 3
        ) {
          amount =
            parseFloat(formik.values.plan.amount_4_life) * 3 +
            parseFloat(formik.values.plan.amount_1_life);
        } else if (
          formik.values.plan &&
          formik.values.dependents &&
          formik.values.dependents.length > 3
        ) {
          amount =
            parseFloat(formik.values.plan.amount_4_life) *
              formik.values.dependents.length +
            parseFloat(formik.values.plan.amount_1_life);
        }
      } else if (formik.values.plan.type === 'Pessoal') {
        if (formik.values.recurrency === 'Mensal') {
          if (
            formik.values.plan &&
            (!formik.values.dependents || formik.values.dependents.length === 0)
          ) {
            amount = parseFloat(formik.values.plan.amount_1_life);
          } else if (
            formik.values.plan &&
            formik.values.dependents &&
            formik.values.dependents.length === 1
          ) {
            amount = parseFloat(formik.values.plan.amount_2_life) * 2;
          } else if (
            formik.values.plan &&
            formik.values.dependents &&
            formik.values.dependents.length === 2
          ) {
            amount = parseFloat(formik.values.plan.amount_3_life) * 3;
          } else if (
            formik.values.plan &&
            formik.values.dependents &&
            formik.values.dependents.length === 3
          ) {
            amount = parseFloat(formik.values.plan.amount_4_life) * 4;
          } else if (
            formik.values.plan &&
            formik.values.dependents &&
            formik.values.dependents.length > 3
          ) {
            amount =
              parseFloat(formik.values.plan.amount_4_life) *
              (formik.values.dependents.length + 1);
          }
        } else if (formik.values.recurrency === 'Anual') {
          if (
            formik.values.plan &&
            (!formik.values.dependents || formik.values.dependents.length === 0)
          ) {
            amount = parseFloat(formik.values.plan.amount_1_life) * 12;
          } else if (
            formik.values.plan &&
            formik.values.dependents &&
            formik.values.dependents.length === 1
          ) {
            amount = parseFloat(formik.values.plan.amount_2_life) * 2 * 12;
          } else if (
            formik.values.plan &&
            formik.values.dependents &&
            formik.values.dependents.length === 2
          ) {
            amount = parseFloat(formik.values.plan.amount_3_life) * 3 * 12;
          } else if (
            formik.values.plan &&
            formik.values.dependents &&
            formik.values.dependents.length === 3
          ) {
            amount = parseFloat(formik.values.plan.amount_4_life) * 4 * 12;
          } else if (
            formik.values.plan &&
            formik.values.dependents &&
            formik.values.dependents.length > 3
          ) {
            amount =
              parseFloat(formik.values.plan.amount_4_life) *
              (formik.values.dependents.length + 1) *
              12;
          }
        }
      }
    }
    return amount;
  };

  function formatDate(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return (
      date.getDate() +
      '/' +
      (date.getMonth() + 1) +
      '/' +
      date.getFullYear() +
      '  ' +
      strTime
    );
  }

  const handleSendProposal = () => {
    formik.setFieldValue('status', 'Pendente');
    api
      .put('/proposals', {
        ...formik.values,
        _id: proposalID,
        status: 'Pendente',
        acceptedDate: formatDate(new Date()),
      })
      .then((response) => {
        setProposalID(response.data['_id']);
        setModalShow(true);
      });
  };

  const goToStep1 = () => {
    setStep1(true);
    setStep2(false);
    setStep3(false);
    setStep4(false);
    setStep5(false);
    setStep6(false);
    formik.submitForm();
  };

  const goToStep2 = () => {
    setStep1(false);
    setStep2(true);
    setStep3(false);
    setStep4(false);
    setStep5(false);
    setStep6(false);
    formik.submitForm();
  };

  const goToStep3 = () => {
    setStep1(false);
    setStep2(false);
    setStep3(true);
    setStep4(false);
    setStep5(false);
    setStep6(false);
    formik.submitForm();
  };

  const goToStep4 = () => {
    setStep1(false);
    setStep2(false);
    setStep3(false);
    setStep4(true);
    setStep5(false);
    setStep6(false);
    formik.submitForm();
  };

  const goToStep5 = () => {
    setStep1(false);
    setStep2(false);
    setStep3(false);
    setStep4(false);
    setStep5(true);
    setStep6(false);
    formik.submitForm();
  };

  const goToStep6 = () => {
    setStep1(false);
    setStep2(false);
    setStep3(false);
    setStep4(false);
    setStep5(false);
    setStep6(true);
    formik.submitForm();
  };

  const getAddress = async (event) => {
    let data = event.target.name.split('.');
    const res = await fetch(
      `https://viacep.com.br/ws/${formik.values[data[0]][data[1]]}/json/`
    );
    let test = await res.json();
    formik.setFieldValue(`${data[0]}.address`, `${test.logradouro || ''}`);
    formik.setFieldValue(`${data[0]}.neightborhood`, `${test.bairro || ''}`);
    formik.setFieldValue(`${data[0]}.city`, `${test.localidade || ''}`);
  };

  const handleAddDependent = () => {
    setIsAllDependentsOK(false);
    let newFormik = [
      ...formik.values.dependents,
      {
        ...dependentsModel,
      },
    ];
    formik.setFieldValue('dependents', newFormik);
    formik.submitForm();
  };

  const removeDependent = (key) => {
    let list = [...formik.values.dependents];
    list.splice(key, 1);
    formik.setFieldValue('dependents', list);
    formik.submitForm();
  };

  const handleDocumentSubmit = async (event) => {
    setSendingDocument(true);
    const formData = new FormData();
    const docfile = document.querySelector('#documentInput');
    // 'fileimage' e o campo que o 'multer' procura o arquivo de imagem.
    formData.append('document', docfile.files[0]);
    api
      .post('/documentUpload', formData, {
        headers: {
          'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
        },
      })
      .then(
        (response) => {
          formik.values.documents = [
            ...formik.values.documents,
            {
              name: docName,
              path: response.data.filePath,
            },
          ];
          formik.submitForm();
          setSendingDocument(false);
          docfile.value = null;
        },
        () => {
          setSendingDocument(false);
        }
      );
  };

  return (
    <Container>
      <HeaderPage>
        <TitlePage>Preenchimento da Proposta</TitlePage>
        <SubTitlePage>Insira suas informações para a proposta</SubTitlePage>
      </HeaderPage>
      <Form onSubmit={formik.handleSubmit}>
        <Row>
          <Col xs={12} md={8}>
            <CardForm>
              <Row>
                {step1 && (
                  <Col xs={12} md={2}>
                    <BtnWizzard active={step1}>Dados Básicos</BtnWizzard>
                  </Col>
                )}
                {step2 && (
                  <Col xs={12} md={2}>
                    <BtnWizzard active={step2}>Titular</BtnWizzard>
                  </Col>
                )}

                {step3 && planType && planType !== 'Empresarial' && (
                  <Col xs={12} md={2}>
                    <BtnWizzard active={step3}>
                      Responsável Financeiro
                    </BtnWizzard>
                  </Col>
                )}
                {step4 && (
                  <Col xs={12} md={2}>
                    <BtnWizzard active={step4}>Dependentes</BtnWizzard>
                  </Col>
                )}
                {step5 && (
                  <Col xs={12} md={2}>
                    <BtnWizzard active={step5}>Dados Complementares</BtnWizzard>
                  </Col>
                )}
                {step6 && (
                  <Col xs={12} md={2}>
                    <BtnWizzard active={step6}>Documentos</BtnWizzard>
                  </Col>
                )}
              </Row>

              {step1 && (
                <WizzardBody>
                  <StepInfo text="Para continuar, favor preencher todos os campos abaixo:" />
                  <Row>
                    <Col xs={12} md={12}>
                      <Form.Group>
                        <LabelField>
                          Nome
                          {errors && errors.customerName !== '' ? (
                            <img
                              src={ErrorImg}
                              style={{ marginLeft: 8, marginTop: -4 }}
                            />
                          ) : null}
                        </LabelField>
                        <InputField
                          type="text"
                          name="customer.name"
                          className="animate__animated animate__flipInX"
                          onChange={formik.handleChange}
                          onBlur={formik.submitForm}
                          value={formik.values.customer.name}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <Form.Group>
                        <LabelField>Email</LabelField>
                        <InputField
                          type="text"
                          name="customer.email"
                          className="animate__animated animate__flipInX"
                          onChange={formik.handleChange}
                          onBlur={formik.submitForm}
                          value={formik.values.customer.email}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group>
                        <LabelField>
                          Telefone
                          {errors && errors.customerPhone !== '' ? (
                            <img
                              src={ErrorImg}
                              style={{ marginLeft: 8, marginTop: -4 }}
                            />
                          ) : null}
                        </LabelField>
                        <InputMask
                          type="text"
                          name="customer.phone"
                          alwaysShowMask="false"
                          style={style}
                          className="animate__animated animate__flipInX"
                          onChange={formik.handleChange}
                          onBlur={formik.submitForm}
                          mask="(99) 99999-9999"
                          value={formik.values.customer.phone}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <WizzardFooter>
                    <span></span>
                    {formik.values.customer.name &&
                      formik.values.customer.phone && (
                        <StepNextBtn
                          className="animate__animated animate__fadeInRight"
                          onClick={goToStep2}>
                          Avançar
                        </StepNextBtn>
                      )}
                  </WizzardFooter>
                </WizzardBody>
              )}
              {step2 && (
                <WizzardBody>
                  <StepInfo text="Preencha todos os campos do titular da proposta" />
                  <Row>
                    <Col xs={12} md={12}>
                      <Form.Group>
                        <LabelField>
                          Nome Completo
                          {errors && errors.customerName ? (
                            <img
                              src={ErrorImg}
                              style={{ marginLeft: 8, marginTop: -4 }}
                            />
                          ) : null}
                        </LabelField>
                        <InputField
                          type="text"
                          name="customer.name"
                          className="animate__animated animate__flipInX"
                          onChange={formik.handleChange}
                          onBlur={formik.submitForm}
                          value={formik.values.customer.name}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <Form.Group>
                        <LabelField>
                          Telefone
                          {errors && errors.customerPhone ? (
                            <img
                              src={ErrorImg}
                              style={{ marginLeft: 8, marginTop: -4 }}
                            />
                          ) : null}
                        </LabelField>
                        <InputMask
                          type="text"
                          name="customer.phone"
                          style={style}
                          mask="(99) 99999-9999"
                          alwaysShowMask={false}
                          className="animate__animated animate__flipInX"
                          onChange={formik.handleChange}
                          onBlur={formik.submitForm}
                          value={formik.values.customer.phone}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group>
                        <LabelField>Email</LabelField>
                        <InputField
                          type="text"
                          name="customer.email"
                          className="animate__animated animate__flipInX"
                          onChange={formik.handleChange}
                          onBlur={formik.submitForm}
                          value={formik.values.customer.email}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group>
                        <LabelField>
                          Data de Nascimento
                          {errors && errors.customerBorn !== '' ? (
                            <img
                              src={ErrorImg}
                              style={{ marginLeft: 8, marginTop: -4 }}
                            />
                          ) : null}
                        </LabelField>
                        <InputMask
                          className="animate__animated animate__flipInX"
                          type="text"
                          style={style}
                          mask="99/99/9999"
                          alwaysShowMask={false}
                          name="customer.born"
                          onChange={formik.handleChange}
                          onBlur={formik.submitForm}
                          value={formik.values.customer.born}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group>
                        <LabelField>
                          CPF
                          {errors && errors.customerSocialNumber !== '' ? (
                            <img
                              src={ErrorImg}
                              style={{ marginLeft: 8, marginTop: -4 }}
                            />
                          ) : null}
                        </LabelField>
                        <InputMask
                          type="text"
                          name="customer.social_number"
                          style={style}
                          mask="999.999.999-99"
                          alwaysShowMask={false}
                          className="animate__animated animate__flipInX"
                          onChange={formik.handleChange}
                          onBlur={formik.submitForm}
                          value={formik.values.customer.social_number}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <Form.Group controlId={`customer.sex`}>
                        <LabelField>
                          Sexo
                          {errors && errors.customerSex !== '' ? (
                            <img
                              src={ErrorImg}
                              style={{ marginLeft: 8, marginTop: -4 }}
                            />
                          ) : null}
                        </LabelField>
                        <InputField
                          as="select"
                          name={`customer.sex`}
                          onChange={formik.handleChange}
                          onBlur={formik.submitForm}
                          className="animate__animated animate__flipInX"
                          value={formik.values.customer.sex}>
                          <option></option>
                          <option>Masculino</option>
                          <option>Feminino</option>
                        </InputField>
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group>
                        <LabelField>
                          Nome da Mãe
                          {errors && errors.customerParentName !== '' ? (
                            <img
                              src={ErrorImg}
                              style={{ marginLeft: 8, marginTop: -4 }}
                            />
                          ) : null}
                        </LabelField>
                        <InputField
                          type="text"
                          name="customer.parentName"
                          className="animate__animated animate__flipInX"
                          onChange={formik.handleChange}
                          onBlur={formik.submitForm}
                          value={formik.values.customer.parentName}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={3}>
                      <Form.Group>
                        <LabelField>
                          CEP
                          {errors && errors.customerPostalCode !== '' ? (
                            <img
                              src={ErrorImg}
                              style={{ marginLeft: 8, marginTop: -4 }}
                            />
                          ) : null}
                        </LabelField>
                        <InputField
                          type="text"
                          name="customer.postalCode"
                          className="animate__animated animate__flipInX"
                          onChange={formik.handleChange}
                          onBlur={getAddress}
                          value={formik.values.customer.postalCode}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group>
                        <LabelField>
                          Logradouro
                          {errors && errors.customerAddress !== '' ? (
                            <img
                              src={ErrorImg}
                              style={{ marginLeft: 8, marginTop: -4 }}
                            />
                          ) : null}
                        </LabelField>
                        <InputField
                          type="text"
                          name="customer.address"
                          className="animate__animated animate__flipInX"
                          onChange={formik.handleChange}
                          onBlur={formik.submitForm}
                          value={formik.values.customer.address}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={3}>
                      <Form.Group>
                        <LabelField>
                          Bairro
                          {errors && errors.customerNeightborhood !== '' ? (
                            <img
                              src={ErrorImg}
                              style={{ marginLeft: 8, marginTop: -4 }}
                            />
                          ) : null}
                        </LabelField>
                        <InputField
                          type="text"
                          name="customer.neightborhood"
                          className="animate__animated animate__flipInX"
                          onChange={formik.handleChange}
                          onBlur={formik.submitForm}
                          value={formik.values.customer.neightborhood}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={8}>
                      <Form.Group>
                        <LabelField>
                          Cidade
                          {errors && errors.customerCity !== '' ? (
                            <img
                              src={ErrorImg}
                              style={{ marginLeft: 8, marginTop: -4 }}
                            />
                          ) : null}
                        </LabelField>
                        <InputField
                          type="text"
                          name="customer.city"
                          className="animate__animated animate__flipInX"
                          onChange={formik.handleChange}
                          onBlur={formik.submitForm}
                          value={formik.values.customer.city}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                      <Form.Group>
                        <LabelField>
                          Número
                          {errors && errors.customerNumber !== '' ? (
                            <img
                              src={ErrorImg}
                              style={{ marginLeft: 8, marginTop: -4 }}
                            />
                          ) : null}
                        </LabelField>
                        <InputField
                          type="text"
                          name="customer.number"
                          className="animate__animated animate__flipInX"
                          onChange={formik.handleChange}
                          onBlur={formik.submitForm}
                          value={formik.values.customer.number}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={8}>
                      <Form.Group>
                        <LabelField>Complemento</LabelField>
                        <InputField
                          type="text"
                          name="customer.complement"
                          className="animate__animated animate__flipInX"
                          onChange={formik.handleChange}
                          onBlur={formik.submitForm}
                          value={formik.values.customer.complement}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <WizzardFooter aria-label="Basic example">
                    <StepPreviousBtn
                      className="animate__animated animate__fadeInLeft"
                      onClick={goToStep1}>
                      Voltar
                    </StepPreviousBtn>
                    {formik.values.customer.name &&
                      formik.values.customer.phone &&
                      formik.values.customer.postalCode &&
                      formik.values.customer.city &&
                      formik.values.customer.neightborhood &&
                      formik.values.customer.number &&
                      formik.values.customer.address &&
                      formik.values.customer.sex &&
                      formik.values.customer.born &&
                      formik.values.customer.social_number && (
                        <StepNextBtn
                          className="animate__animated animate__fadeInRight"
                          onClick={
                            planType && planType !== 'Empresarial'
                              ? goToStep3
                              : goToStep4
                          }>
                          Avançar
                        </StepNextBtn>
                      )}
                  </WizzardFooter>
                </WizzardBody>
              )}
              {step3 && (
                <WizzardBody>
                  <StepInfo text="O Titular do plano será responsável financeiro do contrato? Caso seja, basta avançar." />
                  <Row>
                    <Col xs={12} md={6}>
                      <Form.Group controlId={`isFinantialResponsible`}>
                        <LabelField>
                          Você é o responsável financeiro?
                        </LabelField>
                        <InputField
                          as="select"
                          name={`isFinantialResponsible`}
                          className="animate__animated animate__flipInX"
                          onChange={formik.handleChange}
                          onBlur={formik.submitForm}
                          value={formik.values.isFinantialResponsible}>
                          <option>Sim</option>
                          <option>Não</option>
                        </InputField>
                      </Form.Group>
                    </Col>
                  </Row>
                  {formik.values.isFinantialResponsible !== 'Sim' && (
                    <div>
                      <Row>
                        <Col xs={12} md={12}>
                          <Form.Group>
                            <LabelField>Nome</LabelField>
                            <InputField
                              type="text"
                              name="finantialResponsible.name"
                              className="animate__animated animate__flipInX"
                              onChange={formik.handleChange}
                              onBlur={formik.submitForm}
                              value={formik.values.finantialResponsible.name}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={6}>
                          <Form.Group>
                            <LabelField>Email</LabelField>
                            <InputField
                              type="text"
                              name="finantialResponsible.email"
                              className="animate__animated animate__flipInX"
                              onChange={formik.handleChange}
                              onBlur={formik.submitForm}
                              value={formik.values.finantialResponsible.email}
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                          <Form.Group>
                            <LabelField>Telefone</LabelField>
                            <InputMask
                              type="text"
                              name="finantialResponsible.phone"
                              mask="(99) 99999-9999"
                              alwaysShowMask={false}
                              style={style}
                              className="animate__animated animate__flipInX"
                              onChange={formik.handleChange}
                              onBlur={formik.submitForm}
                              value={formik.values.finantialResponsible.phone}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={6}>
                          <Form.Group>
                            <LabelField>Data de Nascimento</LabelField>
                            <InputMask
                              type="text"
                              mask="99/99/9999"
                              alwaysShowMask={false}
                              style={style}
                              name="finantialResponsible.born"
                              className="animate__animated animate__flipInX"
                              onChange={formik.handleChange}
                              onBlur={formik.submitForm}
                              value={formik.values.finantialResponsible.born}
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                          <Form.Group>
                            <LabelField>CPF</LabelField>
                            <InputMask
                              type="text"
                              mask="999.999.999-99"
                              alwaysShowMask={false}
                              style={style}
                              name="finantialResponsible.social_number"
                              className="animate__animated animate__flipInX"
                              onChange={formik.handleChange}
                              onBlur={formik.submitForm}
                              value={
                                formik.values.finantialResponsible.social_number
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={12}>
                          <Form.Group>
                            <LabelField>Nome da Mãe</LabelField>
                            <InputField
                              type="text"
                              name="finantialResponsible.parentName"
                              className="animate__animated animate__flipInX"
                              onChange={formik.handleChange}
                              onBlur={formik.submitForm}
                              value={
                                formik.values.finantialResponsible.parentName
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                          <Form.Group controlId={`finantialResponsible.sex`}>
                            <LabelField>Sexo</LabelField>
                            <InputField
                              as="select"
                              name={`finantialResponsible.sex`}
                              onChange={formik.handleChange}
                              onBlur={formik.submitForm}
                              className="animate__animated animate__flipInX"
                              value={formik.values.finantialResponsible.sex}>
                              <option></option>
                              <option>Masculino</option>
                              <option>Feminino</option>
                            </InputField>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={3}>
                          <Form.Group>
                            <LabelField>CEP</LabelField>
                            <InputField
                              type="text"
                              name="finantialResponsible.postalCode"
                              className="animate__animated animate__flipInX"
                              onChange={formik.handleChange}
                              onBlur={getAddress}
                              value={
                                formik.values.finantialResponsible.postalCode
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                          <Form.Group>
                            <LabelField>Logradouro</LabelField>
                            <InputField
                              type="text"
                              name="finantialResponsible.address"
                              className="animate__animated animate__flipInX"
                              onChange={formik.handleChange}
                              onBlur={formik.submitForm}
                              value={formik.values.finantialResponsible.address}
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={12} md={3}>
                          <Form.Group>
                            <LabelField>Bairro</LabelField>
                            <InputField
                              type="text"
                              name="finantialResponsible.neightborhood"
                              className="animate__animated animate__flipInX"
                              onChange={formik.handleChange}
                              onBlur={formik.submitForm}
                              value={
                                formik.values.finantialResponsible.neightborhood
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={8}>
                          <Form.Group>
                            <LabelField>Cidade</LabelField>
                            <InputField
                              type="text"
                              name="finantialResponsible.city"
                              className="animate__animated animate__flipInX"
                              onChange={formik.handleChange}
                              onBlur={formik.submitForm}
                              value={formik.values.finantialResponsible.city}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={8}>
                          <Form.Group>
                            <LabelField>Número</LabelField>
                            <InputField
                              type="text"
                              name="finantialResponsible.number"
                              className="animate__animated animate__flipInX"
                              onChange={formik.handleChange}
                              onBlur={formik.submitForm}
                              value={formik.values.finantialResponsible.number}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={8}>
                          <Form.Group>
                            <LabelField>Complemento</LabelField>
                            <InputField
                              type="text"
                              name="finantialResponsible.complement"
                              className="animate__animated animate__flipInX"
                              onChange={formik.handleChange}
                              onBlur={formik.submitForm}
                              value={
                                formik.values.finantialResponsible.complement
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  )}
                  <WizzardFooter>
                    <StepPreviousBtn
                      className="animate__animated animate__fadeInLeft"
                      onClick={goToStep2}>
                      Voltar
                    </StepPreviousBtn>
                    {(formik.values.isFinantialResponsible === 'Sim' ||
                      (formik.values.isFinantialResponsible === 'Não' &&
                        formik.values.finantialResponsible.name &&
                        formik.values.finantialResponsible.phone &&
                        formik.values.finantialResponsible.postalCode &&
                        formik.values.finantialResponsible.city &&
                        formik.values.finantialResponsible.neightborhood &&
                        formik.values.finantialResponsible.number &&
                        formik.values.finantialResponsible.address &&
                        formik.values.finantialResponsible.sex &&
                        formik.values.finantialResponsible.born &&
                        formik.values.finantialResponsible.social_number)) && (
                      <StepNextBtn
                        className="animate__animated animate__fadeInRight"
                        onClick={goToStep4}>
                        Avançar
                      </StepNextBtn>
                    )}
                  </WizzardFooter>
                </WizzardBody>
              )}
              {step4 && (
                <WizzardBody>
                  <WizzardContent>
                    <Row>
                      <Col xs={12} md={6}></Col>
                      <Col xs={12} md={6} className="d-flex flex-row-reverse">
                        <BtnHeader
                          onClick={handleAddDependent}
                          className="d-flex flex-row align-items-center">
                          <span>Adicionar um novo dependente</span>
                        </BtnHeader>
                      </Col>
                    </Row>
                    <StepInfo text="Caso tenha dependentes para incluir, favor clicar em no botão + Dependente e preencher todos os dados, caso contrário basta avançar." />
                    {dependentError && (
                      <Alert variant="danger">
                        Favor preencher todos os campos dos dependentes !
                      </Alert>
                    )}
                    {formik.values.dependents.length === 0 && (
                      <p>Você não cadastrou dependentes.</p>
                    )}
                    {formik.values.dependents.map((prop, key) => {
                      return (
                        <WizzardBody>
                          <Row>
                            <Col xs={12} md={6}>
                              <Form.Group controlId={`dependents[${key}].type`}>
                                <LabelField>Tipo de Dependente</LabelField>
                                <InputField
                                  as="select"
                                  name={`dependents[${key}].type`}
                                  className="animate__animated animate__flipInX"
                                  onChange={formik.handleChange}
                                  onBlur={formik.submitForm}
                                  value={formik.values.dependents[key].type}>
                                  <option></option>
                                  <option>Cônjuge</option>
                                  <option>Filho(a)</option>
                                  <option>Agregado(a)</option>
                                  <option>Pai</option>
                                  <option>Mãe</option>
                                </InputField>
                              </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                              <Form.Group controlId={`dependents[${key}].name`}>
                                <LabelField>Nome Completo</LabelField>
                                <InputField
                                  type="text"
                                  name={`dependents[${key}].name`}
                                  className="animate__animated animate__flipInX"
                                  onChange={formik.handleChange}
                                  onBlur={formik.submitForm}
                                  value={formik.values.dependents[key].name}
                                />
                              </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                              <Form.Group controlId={`dependents[${key}].born`}>
                                <LabelField>Data de Nascimento</LabelField>
                                <InputMask
                                  type="text"
                                  mask="99/99/9999"
                                  alwaysShowMask={false}
                                  style={style}
                                  name={`dependents[${key}].born`}
                                  className="animate__animated animate__flipInX"
                                  onChange={formik.handleChange}
                                  onBlur={formik.submitForm}
                                  value={formik.values.dependents[key].born}
                                />
                              </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                              <Form.Group controlId={`dependents[${key}].sex`}>
                                <LabelField>Sexo</LabelField>
                                <InputField
                                  as="select"
                                  name={`dependents[${key}].sex`}
                                  className="animate__animated animate__flipInX"
                                  onChange={formik.handleChange}
                                  onBlur={formik.submitForm}
                                  value={formik.values.dependents[key].sex}>
                                  <option></option>
                                  <option>Masculino</option>
                                  <option>Feminino</option>
                                </InputField>
                              </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                              <Form.Group
                                controlId={`dependents[${key}].social_number`}>
                                <LabelField>CPF (Insira um 0 caso não possua)</LabelField>
                                <InputMask
                                  type="text"
                                  style={style}
                                  mask="999.999.999-99"
                                  alwaysShowMask={false}
                                  name={`dependents[${key}].social_number`}
                                  className="animate__animated animate__flipInX"
                                  onChange={formik.handleChange}
                                  onBlur={formik.submitForm}
                                  value={
                                    formik.values.dependents[key].social_number
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                              <Form.Group
                                controlId={`dependents[${key}].parentName`}>
                                <LabelField>Nome da mãe</LabelField>
                                <InputField
                                  type="text"
                                  name={`dependents[${key}].parentName`}
                                  className="animate__animated animate__flipInX"
                                  onChange={formik.handleChange}
                                  onBlur={formik.submitForm}
                                  value={
                                    formik.values.dependents[key].parentName
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                              <BtnHeaderCancel
                                onClick={() => removeDependent(key)}>
                                Remover Dependente
                              </BtnHeaderCancel>
                            </Col>
                            <Col
                              xs={12}
                              md={6}
                              className="d-flex flex-row-reverse">
                              <BtnHeader
                                onClick={handleAddDependent}
                                className="d-flex flex-row align-items-center">
                                <span>Adicionar um novo dependente</span>
                              </BtnHeader>
                            </Col>
                          </Row>
                          <hr />
                        </WizzardBody>
                      );
                    })}
                  </WizzardContent>
                  <WizzardFooter>
                    <StepPreviousBtn
                      className="animate__animated animate__fadeInLeft"
                      onClick={
                        planType && planType !== 'Empresarial'
                          ? goToStep3
                          : goToStep2
                      }>
                      Voltar
                    </StepPreviousBtn>
                    <StepNextBtn
                      className="animate__animated animate__fadeInRight"
                      onClick={() => {
                        if (
                          formik.values.dependents.length === 0 &&
                          formik.values.plan.type === 'Empresarial'
                        ) {
                          formik.setFieldValue('paymentType', 'Empresa');
                          goToStep5();
                        }
                        if (
                          formik.values.dependents.length === 0 &&
                          formik.values.plan.type !== 'Empresarial'
                        ) {
                          goToStep5();
                        }
                        if (
                          formik.values.dependents.length > 0 &&
                          formik.values.plan.type === 'Empresarial'
                        ) {
                          if (isAllDependentsOK) {
                            if(formik.values.paymentType === 'Empresa'){
                              formik.setFieldValue('paymentType', 'Boleto do ASFAL Saúde');
                            }
                            goToStep5();
                          } else {
                            setDependentError(true);
                          }
                        }
                        if (
                          formik.values.dependents.length > 0 &&
                          formik.values.plan.type !== 'Empresarial'
                        ) {
                          if (isAllDependentsOK) {
                            goToStep5();
                          } else {
                            setDependentError(true);
                          }
                        }
                      }}>
                      Avançar
                    </StepNextBtn>
                  </WizzardFooter>
                </WizzardBody>
              )}
              {step5 && (
                <WizzardBody>
                  <StepInfo text="Favor selecionar se o pagamento do seu plano será Mensal ou Anual, e a forma que deseja realizar o pagamento." />
                  <Row>
                    <Col xs={12} md={6}>
                      {formik.values.plan &&
                        formik.values.plan.type === 'Empresarial' && (
                          <Form.Group controlId={`recurrency`}>
                            <LabelField>
                              Pagamento
                              {errors && errors.recurrency ? (
                                <img
                                  src={ErrorImg}
                                  style={{ marginLeft: 8, marginTop: -4 }}
                                />
                              ) : null}
                            </LabelField>
                            <InputField
                              as="select"
                              name={`recurrency`}
                              className="animate__animated animate__flipInX"
                              onChange={formik.handleChange}
                              onBlur={formik.submitForm}
                              value={formik.values.recurrency}>
                              <option>Mensal</option>
                            </InputField>
                          </Form.Group>
                        )}
                      {formik.values.plan &&
                        formik.values.plan.type === 'Pessoal' && (
                          <Form.Group controlId={`recurrency`}>
                            <LabelField>
                              Pagamento
                              {errors && errors.recurrency ? (
                                <img
                                  src={ErrorImg}
                                  style={{ marginLeft: 8, marginTop: -4 }}
                                />
                              ) : null}
                            </LabelField>
                            <InputField
                              as="select"
                              name={`recurrency`}
                              className="animate__animated animate__flipInX"
                              onChange={formik.handleChange}
                              onBlur={formik.submitForm}
                              value={formik.values.recurrency}>
                              <option>Mensal</option>
                              <option>Anual</option>
                            </InputField>
                            )}
                          </Form.Group>
                        )}
                    </Col>
                    {((formik.values.plan &&
                      formik.values.plan.type === 'Empresarial') ||
                      (formik.values.plan &&
                        formik.values.plan.type === 'Pessoal')) && (
                      <Col xs={12} md={6}>
                        <Form.Group controlId={`paymentType`}>
                          <LabelField>
                            Forma de Pagamento
                            {errors && errors.paymentType ? (
                              <img
                                src={ErrorImg}
                                style={{ marginLeft: 8, marginTop: -4 }}
                              />
                            ) : null}
                          </LabelField>
                          <InputField
                            as="select"
                            name={`paymentType`}
                            className="animate__animated animate__flipInX"
                            onChange={formik.handleChange}
                            onBlur={formik.submitForm}
                            value={formik.values.paymentType}>
                            {formik.values.plan &&
                              formik.values.plan.type === 'Pessoal' && (
                                <option>Cartão</option>
                              )}
                            {formik.values.plan &&
                              formik.values.plan.type === 'Pessoal' && (
                                <option>Boleto</option>
                              )}
                            {formik.values.plan &&
                              formik.values.plan.type === 'Empresarial' &&
                              formik.values.dependents.length === 0 && (
                                <option>Empresa</option>
                              )}
                            {formik.values.plan &&
                              formik.values.plan.type === 'Empresarial' &&
                              formik.values.dependents.length > 0 && (
                                <option>Boleto do ASFAL Saúde</option>
                              )}
                            {formik.values.plan &&
                              formik.values.plan.type === 'Empresarial' &&
                              formik.values.dependents.length > 0 && (
                                <option>Débito em conta</option>
                              )}
                            {formik.values.plan &&
                              formik.values.plan.type === 'Pessoal' && (
                                <option>Débito em conta</option>
                              )}
                          </InputField>
                        </Form.Group>
                      </Col>
                    )}
                  </Row>
                  {formik.values.paymentType === 'Débito em conta' && (
                    <Row>
                      <Col xs={12} md={12}>
                        <Form.Group controlId={`paymentType`}>
                          <LabelField>
                            Banco
                            {errors && errors.accountBank ? (
                              <img
                                src={ErrorImg}
                                style={{ marginLeft: 8, marginTop: -4 }}
                              />
                            ) : null}
                          </LabelField>
                          <InputField
                            as="select"
                            name={`accountBank`}
                            className="animate__animated animate__flipInX"
                            onChange={formik.handleChange}
                            onBlur={formik.submitForm}
                            value={formik.values.accountBank}>
                            <option></option>
                            <option>Banco do Brasil</option>
                            {formik.values.plan &&
                              formik.values.plan.type === 'Empresarial' && (
                                <option>Caixa Econômica</option>
                              )}
                            {formik.values.plan &&
                              formik.values.plan.type === 'Pessoal' && (
                                <option>Sicred</option>
                              )}
                          </InputField>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={6}>
                        <Form.Group>
                          <LabelField>
                            Agência
                            {errors && errors.accountAgency ? (
                              <img
                                src={ErrorImg}
                                style={{ marginLeft: 8, marginTop: -4 }}
                              />
                            ) : null}
                          </LabelField>
                          <InputField
                            type="text"
                            name={`accountAgency`}
                            className="animate__animated animate__flipInX"
                            onChange={formik.handleChange}
                            onBlur={formik.submitForm}
                            value={formik.values.accountAgency}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={6}>
                        <Form.Group>
                          <LabelField>
                            Dígito da Agência (Insira um X caso não possua)
                            {errors && errors.accountAgencyDigit ? (
                              <img
                                src={ErrorImg}
                                style={{ marginLeft: 8, marginTop: -4 }}
                              />
                            ) : null}
                          </LabelField>
                          <InputField
                            type="text"
                            name={`accountAgencyDigit`}
                            className="animate__animated animate__flipInX"
                            onChange={formik.handleChange}
                            onBlur={formik.submitForm}
                            value={formik.values.accountAgencyDigit}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={6}>
                        <Form.Group>
                          <LabelField>
                            Conta
                            {errors && errors.accountNumber ? (
                              <img
                                src={ErrorImg}
                                style={{ marginLeft: 8, marginTop: -4 }}
                              />
                            ) : null}
                          </LabelField>
                          <InputField
                            type="text"
                            name={`accountNumber`}
                            className="animate__animated animate__flipInX"
                            onChange={formik.handleChange}
                            onBlur={formik.submitForm}
                            value={formik.values.accountNumber}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={6}>
                        <Form.Group>
                          <LabelField>
                            Dígito da Conta (Insira um X caso não possua)
                            {errors && errors.accountNumberDigit ? (
                              <img
                                src={ErrorImg}
                                style={{ marginLeft: 8, marginTop: -4 }}
                              />
                            ) : null}
                          </LabelField>
                          <InputField
                            type="text"
                            name={`accountNumberDigit`}
                            className="animate__animated animate__flipInX"
                            onChange={formik.handleChange}
                            onBlur={formik.submitForm}
                            value={formik.values.accountNumberDigit}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col xs={12} md={6}>
                      <LabelField>
                        VOCÊ FOI ATENDIDO POR UM CONSULTOR DA UNIODONTO?
                      </LabelField>
                      <br />
                      <label style={{ marginRight: 16 }}>
                        <input
                          type="radio"
                          name="sellerCode"
                          value={true}
                          style={{ marginRight: 8 }}
                          checked={seller ? true : false}
                          onChange={(e) => {
                            setSeller(true);
                          }}
                        />
                        <span>Sim</span>
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="sellerCode"
                          value={false}
                          style={{ marginRight: 8 }}
                          checked={!seller ? true : false}
                          onChange={(e) => {
                            setSeller(false);
                          }}
                        />
                        <span>Não</span>
                      </label>
                    </Col>
                  </Row>
                  {seller && (
                    <>
                      <Col xs={12} md={12}>
                        <LabelField>QUAL O NOME DO CONSULTOR?</LabelField>
                        <InputField
                          type="text"
                          name={`sellerCode`}
                          className="animate__animated animate__flipInX"
                          onChange={formik.handleChange}
                          onBlur={formik.submitForm}
                          value={formik.values.sellerCode}
                        />
                      </Col>
                    </>
                  )}
                  <WizzardFooter>
                    <StepPreviousBtn
                      className="animate__animated animate__fadeInLeft"
                      onClick={goToStep4}>
                      Voltar
                    </StepPreviousBtn>
                    {(formik.values.paymentType === 'Boleto' ||
                      formik.values.paymentType === 'Boleto do ASFAL Saúde' ||
                      formik.values.paymentType === 'Cartão' ||
                      formik.values.paymentType === 'Empresa' ||
                      (formik.values.paymentType === 'Débito em conta' &&
                        formik.values.accountBank &&
                        formik.values.accountAgency &&
                        formik.values.accountNumber &&
                        formik.values.accountAgencyDigit &&
                        formik.values.accountNumberDigit)) &&
                      formik.values.recurrency && (
                        <StepNextBtn
                          className="animate__animated animate__fadeInRight"
                          onClick={goToStep6}>
                          Avançar
                        </StepNextBtn>
                      )}
                  </WizzardFooter>
                </WizzardBody>
              )}
              {step6 && (
                <WizzardBody>
                  <StepInfo text="Abaixo listamos os documentos necessários para sua proposta, favor anexar." />
                  {planType && planType !== 'Empresarial' && <PersonDocInfo />}
                  {planType && planType === 'Empresarial' && <PjDocInfo />}
                  <Row>
                    <Col xs={12} md={6}>
                      <Form.Group>
                        <LabelField>Selecione o tipo de documento</LabelField>
                        <InputField
                          as="select"
                          className="animate__animated animate__flipInX"
                          onChange={(event) => setDocName(event.target.value)}
                          value={docName}>
                          <option>Titular - Identidade ou CNH</option>
                          <option>Titular - Comprovante de Residência</option>
                          <option>Titular - Comprovante Escolar</option>
                          <option>
                            Responsável Financeiro - Identidade ou CNH
                          </option>
                          <option>
                            Responsável Financeiro - Comprovante de Residência
                          </option>
                          <option>
                            Dependente (Cônjuge) - Identidade ou CNH
                          </option>
                          <option>
                            Dependente (Cônjuge) - Certidão de Casamento ou
                            Declaração de União Estável
                          </option>
                          <option>
                            Dependente (Filho(a)) - Identidade ou CNH
                          </option>
                          <option>
                            Dependente (Filho(a)) - Certidão de Nascimento
                          </option>
                          <option>
                            Dependente (Agregado(a)) - Identidade ou CNH
                          </option>
                          <option>Pai - Identidade ou CNH</option>
                          <option>Mãe - Identidade ou CNH</option>
                        </InputField>
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group>
                        <LabelField>Escolha o documento:</LabelField>
                        <InputField
                          id="documentInput"
                          className="animate__animated animate__flipInX"
                          onChange={(event) => {
                            setDoc(event.target.value);
                          }}
                          type="file"
                          name="document"
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={12}>
                      <Form.Group>
                        <Button
                          disabled={sendingDocument}
                          onClick={handleDocumentSubmit}
                          variant="light">
                          <img src={UploadImg} style={{ marginRight: 8 }} />
                          Enviar
                        </Button>
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={12}>
                      <Form.Group>
                        {formik.values.documents.length === 0 && (
                          <Alert variant="primary">
                            Nenhum documento anexado!
                          </Alert>
                        )}
                        {formik.values.documents.map((prop, key) => {
                          return (
                            <Alert variant="success" key={key}>
                              {`${prop.name}`} anexado!
                            </Alert>
                          );
                        })}
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={12}>
                      <LabelField>
                        <input
                          type="checkbox"
                          value={accept}
                          onChange={(e) => {
                            if (
                              e.target.value === 'true' ||
                              e.target.value === 'on'
                            ) {
                              setAccept(false);
                            } else {
                              setAccept(true);
                            }
                          }}
                        />
                        {' '}{' '}Declaro que li e concordo integralmente com:{' '}
                        <a href={DocProposta} target="_blank">
                          Contrato
                        </a>{' '}
                        e{' '}
                        <a href={DocAditivo} target="_blank">
                          Aditivo
                        </a>
                      </LabelField>
                    </Col>
                  </Row>
                  <WizzardFooter>
                    <StepPreviousBtn
                      variant="outline-danger"
                      onClick={
                        formik.values.dependents &&
                        formik.values.dependents.length > 0
                          ? goToStep5
                          : goToStep5
                      }>
                      Voltar
                    </StepPreviousBtn>
                    {formik.values.documents.length > 0 && accept && (
                      <StepNextBtn
                        className="animate__animated animate__fadeInRight"
                        onClick={handleSendProposal}>
                        Enviar Proposta
                      </StepNextBtn>
                    )}
                  </WizzardFooter>
                </WizzardBody>
              )}
            </CardForm>
          </Col>
          <Col xs={12} md={4}>
            <CardForm>
              <ResumeTitle>Resumo da Proposta</ResumeTitle>
              <ResumeItem>
                <ResumeItemTitle>Plano</ResumeItemTitle>
                <ResumeItemSubTitle>
                  {formValues && formValues.plan.name}
                </ResumeItemSubTitle>
              </ResumeItem>

              <ResumeItem>
                <ResumeItemTitle>Nome</ResumeItemTitle>
                <ResumeItemSubTitle>
                  {formValues && formValues.customer.name}
                </ResumeItemSubTitle>
              </ResumeItem>

              <ResumeItem>
                <ResumeItemTitle>Dependentes</ResumeItemTitle>
                <ResumeItemSubTitle>
                  {formValues && formValues.dependents.length}
                </ResumeItemSubTitle>
              </ResumeItem>

              <ResumeItemDashed>
                <ResumeItemTitle>Pagamento</ResumeItemTitle>
                <ResumeItemSubTitle>
                  {formValues &&
                    `${formValues.paymentType || ''} - ${
                      formValues.recurrency || ''
                    }`}
                </ResumeItemSubTitle>
              </ResumeItemDashed>

              <ResumeItemTotal>
                <ResumeItemTitle>
                  {`Total ${
                    formValues && formValues.recurrency
                      ? formValues.recurrency
                      : ''
                  }`}
                </ResumeItemTitle>
                <ResumeItemSubTotal>
                  {formValues && formValues.amount > 0 && (
                    <NumberFormat
                      fixedDecimalScale={true}
                      decimalScale={2}
                      value={formValues.amount}
                      displayType={'text'}
                      thousandSeparator="."
                      isNumericString={true}
                      decimalSeparator={','}
                      prefix={'R$ '}
                    />
                  )}
                  {formValues && formValues.amount == 0 && 'Isento'}
                </ResumeItemSubTotal>
              </ResumeItemTotal>
            </CardForm>
          </Col>
        </Row>
      </Form>
      <Modal
        show={modalShow}
        size="sm"
        onHide={() => {
          setModalShow(false);
          document.location.replace('https://www.uniodontomaceio.com.br');
        }}>
        <Modal.Header closeButton>
          <Modal.Title>Proposta preenchida com sucesso</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Sua proposta será enviada para o nosso setor de cadastro e caso haja
          necessidade, entraremos em contato para confirmação de alguns dados.
        </Modal.Body>
      </Modal>
    </Container>
  );
}
