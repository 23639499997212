import React from 'react';

import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';

import {
  PlanContainer,
  PlanCardHeader,
  PlanCardHeaderTitle,
  PlanCardHeaderValue,
  PlanCardDetailTxt,
  PlanCardInfor,
  PlanCardObs,
  PlanCardFooter,
  PlanCardBtn,
  PlanCardDetailTxtStrong,
} from './styles';

export default function PlanCard({ plan }) {
  /**
   * @description Render a card of plan
   * */
  const renderPlanCard = () => {
    return (
      <PlanContainer>
        <PlanCardHeader>
          <PlanCardHeaderTitle>{plan.name}</PlanCardHeaderTitle>
          <PlanCardHeaderValue>
            <NumberFormat
              fixedDecimalScale={true}
              decimalScale={2}
              value={plan.amount_1_life}
              displayType={'text'}
              thousandSeparator="."
              isNumericString={true}
              decimalSeparator={','}
              prefix={'R$ '}
            />
          </PlanCardHeaderValue>
        </PlanCardHeader>
        <PlanCardInfor>{plan.description}</PlanCardInfor>
        <PlanCardDetailTxt>
          Para saber mais,
          <a href={plan.detail_url} target="_blank">
            <PlanCardDetailTxtStrong>clique aqui !</PlanCardDetailTxtStrong>
          </a>
        </PlanCardDetailTxt>
        <PlanCardObs>
          OBS.: Valor por pessoa. Para planos com 2 ou mais pessoas, terá
          descontos progressivos de acordo com a quantidade.
        </PlanCardObs>
        <PlanCardFooter>
            {plan.type === 'Empresarial' && plan.company === 'GENERICO' &&
              <Link to={`/proposalFisical?planId=${plan._id}`}>
                <PlanCardBtn>Contratar</PlanCardBtn>
              </Link>
            }
            {plan.type === 'Empresarial' && plan.company !== 'GENERICO' &&
              <Link to={`/proposal?planId=${plan._id}`}>
                <PlanCardBtn>Contratar</PlanCardBtn>
              </Link>
            }
            {plan.type !== 'Empresarial' &&
              <Link to={`/proposalFisical?planId=${plan._id}`}>
                <PlanCardBtn>Contratar</PlanCardBtn>
              </Link>
            }
        </PlanCardFooter>
      </PlanContainer>
    );
  };

  return <>{plan && renderPlanCard()}</>;
}
