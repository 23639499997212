import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { BackgroundContainer, Title, Text, Btn } from './styles';
import { Link } from 'react-router-dom';

export default function HomeText() {
  return (
    <BackgroundContainer>
      <Container>
        <Row>
          <Col>
            <Title>
              Aqui você encontra planos mensais, anuais e sem carência
            </Title>
            <Text>
              Não perca mais tempo para ter um sorriso saudável! Venha para a
              Uniodonto!
            </Text>
          </Col>
        </Row>
      </Container>
      <Link to="/plans">
        <Btn>Conhecer Nossos Planos</Btn>
      </Link>
    </BackgroundContainer>
  );
}
