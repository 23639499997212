import React, { useState, useContext, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useLocation, useHistory } from 'react-router-dom';
import { HeaderPage, TitlePage, CardForm, SubTitlePage } from '../styles';

import FisicalContext from '../../../context/fisical';
import SelectedPlanDescription from '../../../widgets/SelectedPlanDescription';

import Step1 from './step1';
import Step2 from './step2';
import FinancialResponsibleStep from './financial_responsible.step';
import DependentsStep from './dependets.step';
import PaymentSelectionStep from './payment_selection.step';
import ReviewStep from './review.step';
import CreditCardStep from './credit-card.step';

export default function Steps() {
  //
  const { step, formData, calcProposalAmount } = useContext(FisicalContext);
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const planId = urlParams.get('planId');

  return (
    <>
      <HeaderPage>
        <TitlePage>Preenchimento da Proposta</TitlePage>
        <SubTitlePage>Insira suas informações para a proposta</SubTitlePage>
      </HeaderPage>
      <Row>
        {formData.status === 'Pendente' && (
          <Col xs={12} md={8}>
            <ReviewStep />
          </Col>
        )}
        {formData.status !== 'Pendente' && (
          <Col xs={12} md={8}>
            {step === 1 && <Step1 />}
            {step === 2 && <Step2 />}
            {step === 3 && <FinancialResponsibleStep />}
            {step === 4 && <DependentsStep />}
            {step === 5 && <PaymentSelectionStep />}
            {step === 6 && <ReviewStep />}
            {step === 7 && <CreditCardStep />}
          </Col>
        )}
        <Col xs={12} md={4}>
          <SelectedPlanDescription
            proposal={formData}
            planId={planId}
            calcProposalAmount={calcProposalAmount}
          />
        </Col>
      </Row>
    </>
  );
}
